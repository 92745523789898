import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckSquareOutlined } from '@ant-design/icons';
import classes from './SuccessfulPayment.module.scss';
import { useAppSelector } from 'store/hook';

type SuccessfulPaymentPops = {
  isOpen: boolean;
  onCancel: () => void;
};

const SuccessfulPayment = ({ isOpen, onCancel }: SuccessfulPaymentPops) => {
  const { userData } = useAppSelector((store) => store.userData);

  const { t } = useTranslation();
  
  return (
    <Modal
      open={isOpen}
      width={'60%'}
      onCancel={onCancel}
      onOk={onCancel}
      centered
      cancelButtonProps={{style: {display: 'none'}}}
    >
      <div className={classes.confirmWrap}>
        <CheckSquareOutlined className={classes.successIcon}/>
        <div className={classes.textConfirm}>
          <p>{t('payment.dear')} {userData?.first_name} {userData?.last_name}</p>
          <p>{t('payment.pleasedOffer')}</p>
          <p>{t('payment.available')}</p>
          <p>{t('payment.thanks')}</p>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessfulPayment;
