import React, { ReactNode } from 'react';
import classes from '../SingingTab.module.scss'
import { message, TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { prepareGroups } from '../../DetailsTabs/AgreementCard';
import { ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import EnterPasswordModal from '../SigningComponents/EnterPasswordModal';
import ActionButtons from '../ActionButtons/ActionButtons';
import CardTitle from '../SigningComponents/CardTitle';
import { setDocuments, setSelectedDocument } from 'store/slices/dataDocumentsSlice';
import { setSigningState } from 'store/slices/signingSlice';
import DeclineSigningModal from '../SigningComponents/DeclineSigningModal';
import convert from 'api/convert';
import dayjs from 'dayjs';

type SigningCardProps = {
  closeDetailsModal: () => void;
  deleteSigning: () => Promise<void>;
};

const SigningCard = ({closeDetailsModal, deleteSigning}: SigningCardProps) => {
  const [isAddingSgining, setIsAddingSgining] = React.useState(false);
  const [isPasswordSended, setIsPasswordSended] = React.useState(false);
  const [usersId, setUsersId] = React.useState<string[]>([]);
  const [dataTreeSelect, setDataTreeSelect] = React.useState<any[]>([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState(false);
  const [isOpenDeclineSigningModal, setIsOpenDeclineSigningModal] = React.useState(false);

  const [loadingProcess, setLoadingProcess] = React.useState({sendPass: false, decline: false, delete: false});

  const { selectedDocument, documents, dataDocumentTable, folders } = useAppSelector((state) => state.documents);
  const { users, groups, isGroupLoading } = useAppSelector((store) => store.dataUsers);
  const { userData } = useAppSelector((store) => store.userData);
  const { signing, needSigningFromUsers, isSigningLoading, signingInfo, mySigning } = useAppSelector((store) => store.signing);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    users[0] && setUsersId(needSigningFromUsers.map(user => user.user_id));
  },[signing, users]);

  React.useEffect(() => {
    const usersWithSignStatus = users.map(user => ({
      ...user,
      signing_status: needSigningFromUsers.find(signUser => signUser.user_email === user.email)?.signing_status,
    }))
    
    const treeData = groups.map(group => prepareGroups(group, usersWithSignStatus, t, 'can_manage_signing'));
    setDataTreeSelect(treeData);
  },[users]);

  const onChange = (newValue: string[], label: ReactNode[], extra: any) => {
    setUsersId(newValue);
  };

  const onOpenDeclineModal = () => setIsOpenDeclineSigningModal(true);

  const tProps = {
    treeData: dataTreeSelect,
    value: usersId,
    onChange,
    treeCheckable: true,
    showCheckedStrategy: TreeSelect.SHOW_CHILD,
    placeholder: t('Documents.details.signing.treePlaceholder'),
    // style: { width: isEnable ? '80%' : '100%' },
  };

  const documentPermissions = dataDocumentTable.find(({ id }) => id === selectedDocument?.id)?.permissions!;

  const sendRequestForApproval = async () => {
    const choosenUsers = users.filter(user => usersId.includes(user.id)).map(user => {
      // const targetUserState = needAgreementFromUsers.find(someUser => someUser.user_id === user.id)?.agreement_state;
      return {
      user_id: user.id,
      user_name: user.first_name,
      user_email: user.email,
      signing_status: null, // targetUserState || null,
      parent_group_id: user.group[0]
    }});
    
    const simpleGroups: ISimpleGroupForAprove[] = groups.map(group => ({group_id: group.id, group_name: group.name}));
    
    const signing_state = choosenUsers.reduce((acc: ISimpleGroupForAprove[], currentUser: ISimpleUserForAprove) => {
      const targetGroup = simpleGroups.find(group => group.group_id === currentUser.parent_group_id);
      const theSameGroupInAcc = acc.find((group => group.group_id === targetGroup?.group_id));
      
      delete currentUser.parent_group_id;
      
      theSameGroupInAcc
        ? acc = acc.map((group: any) => (group.group_id === targetGroup?.group_id ? {...group, users: [...group.users, currentUser]} : group))
        : acc.push({...targetGroup!, users: [currentUser]})
      
      return acc;
    }, []);

    setIsAddingSgining(true);

    try {
      await convert.addSigning({
        document_id: selectedDocument?.id!,
        signing_state
      });
      
      dispatch(setDocuments(
        {
          documents: documents.map(document => {
            return document.id === selectedDocument!.id
            ? { ...document, signing_status: 0, permissions: documentPermissions, status: 0 }
            : document
          }),
          folders
        }
      ))
      dispatch(setSelectedDocument({...selectedDocument!, signing_status: 0}));
      message.success(t('Documents.details.signing.sendForSigning'));
      closeDetailsModal();
    } catch (e) {
      message.error(t('Documents.details.signing.sendForSigningErr'));
    } finally {
      setIsAddingSgining(false);
    }
  };

  const getСonfirmationPassword = async () => {
    setLoadingProcess({...loadingProcess, sendPass: true});
    try {
      await convert.requestSigning({document_id: selectedDocument?.id!});
      message.success(`${t('Documents.details.signing.successConfirmPassword')}: ${userData?.email}`, 10);
      setIsPasswordSended(true);
      setIsOpenConfirmModal(true);
    } catch (e) {
      message.error(t('Documents.details.signing.errorConfirmPassword'))
    } finally {
      setLoadingProcess({...loadingProcess, sendPass: false});
    }
  }

  const signDocument = async (password: string) => {
    setIsAddingSgining(true);
    try {
      await convert.changeSigntStatus({document_id: signingInfo?.id!, sign_password:password});

      const newMySigning = mySigning
        ? {...mySigning, signing_status: !mySigning.signing_status, signing_date: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS') }
        : null

      const newNeedSigningFromUsers = needSigningFromUsers.map(signingFromUser => 
        (signingFromUser.user_id === newMySigning?.user_id ? newMySigning : signingFromUser)
      );

      const newSigning = signing.map(signItem => ({
        ...signItem,
        users: signItem.users?.map(user => user.user_id === newMySigning?.user_id ? newMySigning : user)
      }))

      dispatch(setSigningState({
        mySigning: newMySigning,
        needSigningFromUsers: newNeedSigningFromUsers,
        signing: newSigning,
      }));

      const isAllApprovedDoc = !newSigning.map(group => {
        const aprovedUser = group.users?.find(user => user.signing_status === true)
        return Boolean(aprovedUser)
      });
      
      dispatch(
        setDocuments({
          documents: documents.map((document) => (document.id === selectedDocument!.id
            ? {
              ...document,
              signing_status: isAllApprovedDoc ? 0 : 2,
              permissions: documentPermissions,
            } : document
          )),
          folders,
        })
      );

      message.success(t('Documents.details.signing.signedSuccessfully'));
    } catch (e) {
      message.error(t('Documents.details.signing.signingError'))
    } finally {
      setIsAddingSgining(false);
    }
  };

  const deleteCurrentSigning = async () => {
    setLoadingProcess({...loadingProcess, delete: true});
    await deleteSigning()
    setLoadingProcess({...loadingProcess, delete: false});
  };

  return (
    <div>
      {userData?.has_sign 
        ? (<div className={classes.signingCard}>
          
          <CardTitle signingCreated={Boolean(signing[0])} />
          <TreeSelect
            disabled={isGroupLoading || isAddingSgining || isSigningLoading || Boolean(signing[0]) || isPasswordSended}
            loading={isGroupLoading || isSigningLoading || isAddingSgining}
            className={classes.singingTree}
            placement='topLeft'
            {...tProps}
          />

          <ActionButtons
            isSigningCreated={Boolean(signing[0])}
            isPasswordSended={isPasswordSended}
            setIsOpenConfirmModal={setIsOpenConfirmModal}
            isAddingSgining={isAddingSgining}
            usersId={usersId}
            sendRequestForApproval={sendRequestForApproval}
            getСonfirmationPassword={getСonfirmationPassword}
            loadingProcess={loadingProcess}
            deleteSigning={deleteCurrentSigning}
            onOpenDeclineModal={onOpenDeclineModal}
          />

          {isOpenConfirmModal && (
            <React.Suspense fallback={<div />}>
              <EnterPasswordModal
                open={isOpenConfirmModal}
                onCancel={() =>setIsOpenConfirmModal(false)}
                signDocument={signDocument}
                isAddingSgining={isAddingSgining}
              />
            </React.Suspense>
          )}
          
          {isOpenDeclineSigningModal && (
            <React.Suspense fallback={<div />}>
              <DeclineSigningModal
                open={isOpenDeclineSigningModal}
                onCancel={() => setIsOpenDeclineSigningModal(false)}
                documentId={selectedDocument?.id!}
                documentPermissions={documentPermissions}
              />
            </React.Suspense>
          )}
          
        </div>)
        : (<div className={classes.needToAcceptRules}>
          {t('Documents.details.signing.needToAcceptRules')}
        </div>) 
      }
    </div>
  );
}

export default SigningCard;
