import { requestTemplate } from 'api';

// TARIFFS
const getTariffs = (tariff_name?: string) => requestTemplate.get(`/tariffs/?tariff_name=${tariff_name}`); // tariff_name: str – если не передать, то будет весь список тарифной сетки

const getRoomsBills = () => requestTemplate.get(`/tariffs/billing/`);

const createTrialRoom = (payload: any) => requestTemplate.post<any>(`/tariffs/trial/?room_name=${payload}`);

const extendRoom = (payload: any) => requestTemplate.post<string>(`/tariffs/extend/`, payload);

const users = {
  getTariffs,
  getRoomsBills,
  createTrialRoom,
  extendRoom
};

export default users;
