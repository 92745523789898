import { CloseOutlined, MessageOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Form, Input, InputRef, MenuProps } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { dateFormatCase } from 'helpers/getDateWithTimeZone';
import { TFunction } from 'i18next';
import { IChat, IChatMessage } from 'interfaces';
import { CSSProperties, FC, KeyboardEvent, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchUnviewedAmount } from 'store/reducers/chatCreator';
import RecieveMessage from './Messages/RecieveMessage';
import SendMessage from './Messages/SendMessage';
import classes from './Messanger.module.scss';
import { setMessageForReply } from 'store/slices/chatsSlice';

type Props = {
  onSendMessage: (event: any) => void;
  onChatUsersOpen: () => void;
  chatGroups: IChatMessage[][];
  chatInfo: IChat | null;
  t: TFunction;
};

const Messanger: FC<Props> = ({ onChatUsersOpen, onSendMessage, t, chatGroups, chatInfo }) => {
  const { userData } = useAppSelector((state) => state.userData);
  const { messageForReply } = useAppSelector((state) => state.chat);

  const dispatch = useAppDispatch();
  const inputRef = useRef<InputRef>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();

  const keydownListener = (e: globalThis.KeyboardEvent) => {
    if (e.code === 'Escape') dispatch(setMessageForReply(null));
  }

  useEffect(() => {
    dispatch(fetchUnviewedAmount({ data: chatInfo, count: -1 }));
    form.setFieldValue('message', '');
    document.addEventListener('keydown', keydownListener);

    return () => {
      document.removeEventListener('keydown', keydownListener);
      dispatch(setMessageForReply(null));
    };
  }, []);

  const scrollToBottom = () => bodyRef.current?.scrollTo({ top: bodyRef.current.scrollHeight + 150 });

  useEffect(() => {
    if (bodyRef.current) scrollToBottom();
  }, [form, bodyRef, chatGroups]);

  const onFinish = (values: any) => {
    onSendMessage(values.message);
    form.resetFields(['message']);
  };

  const submitOnEnterClick = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      setTimeout(() => {
        form.resetFields(['message']);
      }, 1);
      onSendMessage((event.target as HTMLTextAreaElement).value);
    }
  };

  const contextMenu = (message: IChatMessage): MenuProps['items'] => [
    {
      label: <span><MessageOutlined style={{paddingRight: 6, fontSize: 16}}/>{t('Chats.reply')}</span>,
      key: 'reply',
      disabled: message.status === 'pending',
      onClick: () => {
        const replied_to = {
          id: message.id,
          content: message.message,
          author: {
            name: message.user_name,
          },
        }
        dispatch(setMessageForReply(replied_to))
        inputRef.current?.focus();
      },
    },
  ];

  const scrollToElement = (chatMessage: IChatMessage) => {
    const targetMessage = chatMessage?.replied_to?.id && document.getElementById(chatMessage?.replied_to?.id);
    if (targetMessage && targetMessage.style) {      
      targetMessage.style.setProperty('opacity', '.75');
      targetMessage.style.setProperty('transform', 'scale(1.05)');

      setTimeout(() => {
        targetMessage.style.setProperty('opacity', '1');
        targetMessage.style.setProperty('transform', 'scale(1)');
      }, 1000);
      bodyRef.current?.scrollTo({ top: targetMessage.offsetTop - 20, behavior: 'smooth' });
    }
  }
  
  const replyOn = (chatMessage: IChatMessage, isRecieve: boolean) => {    
    return (
      <div onClick={() => scrollToElement(chatMessage)} className={classNames(classes.repliedWrap, isRecieve ? classes.recieve : classes.send)}>
        <div className={classes.repliedAuthor}>{chatMessage.replied_to?.author.name}</div>  
        <div className={classes.repliedMessage}>{chatMessage.replied_to?.content}</div>  
      </div>
    )
  };  

  return (
    <div className={classes.messanger}>
      <div role='navigation' className={classes.messanger_header}>
        <div className={classes.messanger_header_items}>
          <div
            className={classes.messanger_avatars}
            style={{ '--length': chatInfo?.members.length } as CSSProperties}
            onClick={onChatUsersOpen}
          >
            {chatInfo?.members.map((member, idx) => idx > 4 ? null : (
              <Avatar
                key={`avatar-${idx}`}
                style={{ '--idx': idx } as CSSProperties}
                className={classes.messanger_avatar}
                icon={<UserOutlined />}
              />
            ))}
          </div>
        </div>
        <div className={classNames(classes.messanger_title, classes.messanger_header_items)}>
          <div className={classes.messanger_name}>
            {t(`Chats.types.${chatInfo?.entity_type}`)} {chatInfo?.entity_name}
          </div>
          {/* <span className={classes.messanger_date}>{t('Chats.lastSeen')}</span> */}
        </div>
      </div>
      <div className={classes.messanger_body} ref={bodyRef}>
        <div className={classes.messanger_bodyMessages}>
          {chatGroups?.map((chats, index) => (
            <div className={classes.messanger_chatGroups} key={`group-${chats[0]?.timestamp}-${index}`}>
              {chats[0]?.timestamp && (
                <div className={classes.messanger_dateSection}>
                  {dayjs(chats[0]?.timestamp, dateFormatCase[userData?.date_format!]).format('DD/MM/YYYY')}
                </div>
              )}

              {chats.map((chatMessage, idx) => {
                const isFirst = chatMessage.user_name !== chatGroups[index][idx - 1]?.user_name;
                return chatMessage.email === userData?.email ? (
                  <SendMessage
                    key={`message-${chatMessage.timestamp + idx}-sender`}
                    chatMessage={chatMessage}
                    isFirst={isFirst}
                    data={chatMessage}
                    date={`${dayjs(chatMessage.timestamp, dateFormatCase[userData?.date_format!]).format('HH:mm')}`}
                    contextMenu={contextMenu}
                    replyOn={replyOn}
                  />
                ) : (
                  <RecieveMessage
                    key={`message-${chatMessage.timestamp + idx}-recieve`}
                    chatMessage={chatMessage}
                    isFirst={isFirst}
                    date={`${dayjs(chatMessage.timestamp, dateFormatCase[userData?.date_format!]).format('HH:mm')}`}
                    contextMenu={contextMenu}
                    replyOn={replyOn}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <Form onFinish={onFinish} form={form} className={classes.messanger_footer_form}>
        {messageForReply && (
          <div className={classes.replyBlock}>
            <div className={classes.replyWrap}>
              <div className={classes.whom}>{t('Chats.inReply')} {messageForReply?.author?.name}</div>
              <div className={classes.reply}>{messageForReply?.content}</div>
            </div>
            <div className={classes.replyClose}><CloseOutlined onClick={() => dispatch(setMessageForReply(null))}/></div>
          </div>
        )}
        <div className={classNames(classes.messanger_footer, !messageForReply?.content ? classes.borderTop : null)}>
          <Form.Item name='message' className={classes.messanger_footer_item}>
            <Input.TextArea
              ref={inputRef}
              autoFocus
              className={classes.messanger_textarea}
              placeholder={t('Chats.startTyping')}
              autoSize={{ maxRows: 10, minRows: 1 }}
              onKeyDown={submitOnEnterClick}
            />
          </Form.Item>
          <div className={classes.messanger_sendingIcons}>
            <SendOutlined onClick={form.submit} className={classes.messanger_footerIcons} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Messanger;
