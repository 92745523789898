import { FileWordOutlined, FolderOutlined, FolderFilled, FilePdfOutlined, FileExcelOutlined, FilePptOutlined, FileOutlined, YoutubeOutlined, PlayCircleTwoTone, FileImageTwoTone } from '@ant-design/icons';
import classes from './HelpersStyles.module.scss';
import colors from '../scss/variables/colors.module.scss'

export const documnentIcons = {
  docx: <FileWordOutlined className={classes.icon_word} />,
  doc: <FileWordOutlined className={classes.icon_word} />,
  xlsx: <FileExcelOutlined className={classes.icon_excel} />,
  xls: <FileExcelOutlined className={classes.icon_excel} />,
  pptx: <FilePptOutlined className={classes.icon_powerPoint} />,
  ppt: <FilePptOutlined className={classes.icon_powerPoint} />,
  pdf: <FilePdfOutlined className={classes.icon_pdf} />,
  youtube: <YoutubeOutlined className={classes.icon_youtube} />,
  folder: <FolderOutlined className={classes.icon_folder} />,
  rootfolder: <FolderFilled className={classes.icon_folder} />,
  mp4: <PlayCircleTwoTone twoToneColor={colors.word} />,
  mov: <PlayCircleTwoTone twoToneColor={colors.word} />,
  webm: <PlayCircleTwoTone twoToneColor={colors.word} />,
  ogg: <PlayCircleTwoTone twoToneColor={colors.word} />,
  jpeg: <FileImageTwoTone twoToneColor={colors.jpeg}/>,
  jpg: <FileImageTwoTone twoToneColor={colors.jpeg} />,
  png: <FileImageTwoTone twoToneColor={colors.png} />,
  gif: <FileImageTwoTone twoToneColor={colors.gif} />,
  bmp: <FileImageTwoTone twoToneColor={colors.gif} />,
  jfif: <FileImageTwoTone twoToneColor={colors.gif} />,
  default: <FileOutlined className={classes.icon_word}/>
};

export const availableIconFormat = Object.keys(documnentIcons);

