import {
  CheckCircleOutlined,
  LockOutlined,
  PushpinOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { IDocument, IFolder } from 'interfaces';
import React, { FC, MouseEventHandler, useState, MouseEvent } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { setCurrentFolder, setSelectedDocument, setSelectedFolder } from 'store/slices/dataDocumentsSlice';
import { setMoreInfoHidden, setIsOpenDeleteEntityModal, setIsOpenFileDetailsModal, setIsOpenMoveEntityModal, setIsOpenRenameEntityModal, setIsOpenShareEntityModal, setMovingDocument } from 'store/slices/windowStateSlice';
import classes from './DocumentDashboard.module.scss';
import { Popover, Tooltip } from 'antd';
import classNames from 'classnames';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglass } from '@fortawesome/free-solid-svg-icons';
import ContextMenu from 'components/ContextMenu/ContextMenu';
import { TFunction } from 'i18next';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type DashboardItemProps = {
  dataSource: (IDocument | IFolder)[];
  fileItem: IDocument | IFolder;
  t: TFunction;
  selectedRowIds: string[];
  roomId: string;
  handleDropDocuments: (selectedFiles: string[], ids: string[]) => void;
  labelHandle: (e: MouseEvent<HTMLDivElement>, infoRow: any) => void;
  onSelectChange: (id: string) => void;
  openContextModal: (entity: IFolder | IDocument,  openTargetModal: () => void) => void;
  resetContextMenuField: () => void;
  openContextMenu: (event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement>, entity: IDocument | IFolder) => void;
};

const DashboardItem: FC<DashboardItemProps> = ({
  dataSource,
  fileItem,
  t,
  selectedRowIds,
  roomId,
  handleDropDocuments,
  onSelectChange,
  labelHandle,
  openContextModal,
  resetContextMenuField,
  openContextMenu
}) => {
  const [delayClickTimer, setDelayClickTimer] = useState<NodeJS.Timeout>();

  const icon = availableIconFormat.includes(fileItem.type!) ? fileItem.type : 'default';

  const { currentFolder } = useAppSelector((state) => state.documents);
  const { isLoadingProcess, isDeletingDocuments, isUploadingDocuments, isDownloadingDocuments, isActiveContextMenu } = useAppSelector((store) => store.windowState);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const onViewClick: MouseEventHandler<HTMLDivElement> = async (event) => {    
    if (fileItem.type === 'folder') {
      clearTimeout(delayClickTimer);
      dispatch(setSelectedDocument(null));
      dispatch(setMoreInfoHidden(true));
      dispatch(setCurrentFolder([...currentFolder, fileItem]));
      localStorage.setItem('previousFolderPath', JSON.stringify([...currentFolder, fileItem]))
      return;
    }

    event.stopPropagation();
    clearTimeout(delayClickTimer);

    navigate(`${window.location.pathname}?=${fileItem.id}`);
    dispatch(setIsOpenViewer(true));
    await dispatch(
      fetchDocumentToViewer({
        token: fileItem.token,
        extension: fileItem.extension,
        is_agreement: false,
        id: dataRoom?.id,
        entity: fileItem.id || '',
        action: (fileItem as IDocument).is_confidential ? 'view_confident': 'view',
        locale: i18n.language,
      })
    );
  };

  const onRowClick = (file: IDocument) => {
    // if (!isActiveContextMenu) {
    //   if (file.type === 'folder') {
    //     dispatch(setSelectedFolder(file));
    //     dispatch(setSelectedDocument(null));
    //   } else {
    //     dispatch(setSelectedDocument(file));
    //     dispatch(setSelectedFolder(null));
    //   }
    //   // dispatch(setMoreInfoHidden(false));
    // }
  };

  const onDelayRowClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (!isActiveContextMenu) {
      const onDelayCLick = () => {
        labelHandle(event, fileItem);
        // if (fileItem.type === 'folder') return;
        onSelectChange(fileItem.id!);
        const file = fileItem as IDocument;
        onRowClick(file);
      };
  
      clearInterval(delayClickTimer);
      setDelayClickTimer(setTimeout(onDelayCLick, 250));
    }
  };

  const addDragClass = (target: any) => {
    const element = target as HTMLElement;
    element.classList.add(classes.onDrag);
  };

  const removeDragClass = (target: any) => {
    const element = target as HTMLElement;
    element.classList.remove(classes.onDrag);
  };

  const handleDragStart = (event: React.DragEvent<HTMLTableRowElement>) => {
    dispatch(setMovingDocument(true));
    addDragClass(event.target);
    event.dataTransfer.items.add(JSON.stringify(fileItem), 'application/json');
  };

  const handleDragEnd = (event: React.DragEvent<HTMLTableRowElement>) => {
    dispatch(setMovingDocument(false));
    removeDragClass(event.target);
  };

  const handleDragOver = (event: React.DragEvent<HTMLTableRowElement>) => {
    dispatch(setMovingDocument(true));
    event.preventDefault();
    addDragClass(event.target);
  };

  const handleDrop = (event: React.DragEvent<HTMLTableRowElement>) => {
    dispatch(setMovingDocument(false));
    event.preventDefault();
    removeDragClass(event.target);

    const data = event.dataTransfer.items;
    data[0]?.getAsString((s) => {
      const droppedFile = JSON.parse(s);

      const isDrop = selectedRowIds.includes(droppedFile.id!);
      const includedFiles = isDrop ? selectedRowIds : [droppedFile.id!];
      handleDropDocuments(includedFiles, [fileItem.id!]);
    });
  };

  const handleDragLeave = (event: React.DragEvent<HTMLTableRowElement>) => {
    removeDragClass(event.target);
  };

  const showDetails = (entity: any, e?: React.MouseEvent<HTMLElement>,) => {
    e?.stopPropagation();
    if (entity.type === 'folder') {
      dispatch(setSelectedFolder(entity));
      dispatch(setSelectedDocument(null));
    } else {
      dispatch(setSelectedDocument(entity));
      dispatch(setSelectedFolder(null));
    }
    dispatch(setIsOpenFileDetailsModal(true));
  };

  const detailsModalHandle = (info: any, e?: React.MouseEvent<HTMLElement>,) => {
    showDetails(info, e)
    openContextModal(info, () => dispatch(setIsOpenDeleteEntityModal(true)));
  }

  return (
    <div
      className={classNames(classes.dashboard_item,
        (isLoadingProcess || isDeletingDocuments || isUploadingDocuments || isDownloadingDocuments) ? classes.processLoading : classes.simpleIcon)
      }
      draggable={fileItem.type !== 'folder'}
      onDrop={fileItem.type === 'folder' ? handleDrop : undefined}
      onDragOver={fileItem.type === 'folder' ? handleDragOver : undefined}
      onDragLeave={handleDragLeave}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onClick={onDelayRowClick}
      onDoubleClick={onViewClick}
      onContextMenu={(e) => openContextMenu(e, fileItem)}
      onMouseLeave={resetContextMenuField}
      data-clicked={fileItem.clicked}
    >
      <Popover
        overlayInnerStyle={{
          // marginLeft: info.pageX - 120 || 0,
          width: 200,
          padding: 0,
        }}
        arrow={false}
        placement='bottomLeft'
        open={fileItem.isOpenContextMenu}
        content={
          <ContextMenu
            entity={fileItem}
            t={t}
            deleteModalHandle={() => openContextModal(fileItem, () => dispatch(setIsOpenDeleteEntityModal(true)))}
            renameModalHandle={() => openContextModal(fileItem, () => dispatch(setIsOpenRenameEntityModal(true)))}
            moveModalOpenHandle={() => openContextModal(fileItem, () => dispatch(setIsOpenMoveEntityModal(true)))}
            shareModalHandle={() => openContextModal(fileItem, () => dispatch(setIsOpenShareEntityModal(true)))}
            detailsModalHandle={() => detailsModalHandle(fileItem)}
          />
        } 
        trigger='contextMenu'
      >
        <div className={classes.icon}>
          {documnentIcons[icon as keyof typeof documnentIcons]} <RenderCell file={fileItem} t={t} />
        </div>
        <Tooltip title={fileItem.name}>
          <p>{fileItem.name}</p>
        </Tooltip>
      </Popover>
    </div>
  );
};

type RenderCellType = {
  file: IDocument | IFolder;
  t: TFunction;
};

const RenderCell: FC<RenderCellType> = ({ file, t }) => {
  let permissionsRender = null;
  let notesRender = null;
  let agreedRender = null;
  let confidentialRender = null;

  const hasChildrenNote = file.children?.find((doc) => doc.has_notes);
  const fileItem = file as IDocument;

  if (!file.children && file.type === 'folder') {
    permissionsRender = null;
  } else {
    const currentPermissions = file.permissions?.map((permission: string)=> {
      const localized = t(`Logs.logsModal.${permission}`);
      return localized;
    });
    permissionsRender = (
      <Tooltip 
        title={currentPermissions?.join(', ')}
        overlayStyle={{ minWidth: 200, maxWidth: 400 }}
      >
        <LockOutlined />
      </Tooltip>
    );
  }
  
  if (fileItem?.is_confidential) {
    confidentialRender = (<div className={classes.conditentialIcon}>
    <Tooltip title={t('Documents.table.confidential')}> <UnlockOutlined /> </Tooltip>
  </div>)
  }
  
  if (hasChildrenNote || fileItem.has_notes) notesRender = <PushpinOutlined />;
  if (fileItem.is_agreed) agreedRender = <CheckCircleOutlined style={{ color: 'green' }}/>;
  if (fileItem.is_agreed === false) agreedRender = <FontAwesomeIcon style={{ color: 'red' }} icon={faHourglass} />;
  if (!notesRender && !permissionsRender) return null;

  return (
    <div className={classes.sideIcons}>
      {permissionsRender} {confidentialRender} {notesRender} {agreedRender}
    </div>
  );
};

export default DashboardItem;
