export const removeItemsFromLocalStorage = () => {
  localStorage.removeItem('previousChatId');
  localStorage.removeItem('previousFolderId');
  localStorage.removeItem('previousRoomId');
  localStorage.removeItem('previousFolderPath');
  localStorage.removeItem('prevTab');
  localStorage.removeItem('previousPage');
  localStorage.removeItem('document_id');
  localStorage.removeItem('modalSection');
}
