import { Suspense, useState } from 'react';
import { Button, Empty, Layout } from 'antd';
import { useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { NewRoomModal } from 'components/Modals';
import classes from './Rooms.module.scss';

const Rooms = () => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const { userData } = useAppSelector((state) => state.userData);

  const { t } = useTranslation();

  const onCreateRoomClick = () => setIsVisibleModal(true); // navigate('/tariffs'); //setIsVisibleModal(true);

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainSpace}>
        <Layout className={classes.container}>
            {userData?.available_rooms.length
              ? <Empty
                className={classes.tabsEmptyText}
                description={
                  <span>
                    {t('Rooms.chooseRoom')}.
                    <br />
                  </span>
                }
              />
              : <Empty
              className={classes.tabsEmptyText}
              description={
                <span>
                  {t('Documents.management.noAvailable')}.
                  <br />
                  {t('Rooms.prompt')} <br />
                  <Button className={classes.emptyCreate} onClick={onCreateRoomClick}>
                    {t('Header.management.create')}
                  </Button>
                </span>
              }
            />}
        </Layout>
      </div>

      {isVisibleModal && (
        <Suspense fallback={<div />}>
          <NewRoomModal open={isVisibleModal} onCancel={() => setIsVisibleModal(false)} />
        </Suspense>
      )}
    </div>
  );
};

export default Rooms;
