import React from 'react'
import { Divider, Modal, Tabs, TabsProps, message } from 'antd';
import classes from './FileDetailsModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { setIsOpenNotesDeleteModal, setNotes, setSelectedNote } from 'store/slices/dataNotes';
import DeleteModal from '../DeleteModal/DeleteModal';
import api from 'api';
import { AgreementUsers, AgreementMessages, AgreementCard } from './DetailsTabs';
import cn from 'classnames';
import TimeLine from 'components/SidersTimeline/TimeLine';
import { fetchAvailableDocumentsLogs } from 'store/reducers/documentsLogsCreator';
import fetchGroupsAndUsers from 'store/reducers/usersGroupCreator';
import { resetAgreementState } from 'store/slices/dataAgreementsSlice';
import { fetchAgreements } from 'store/reducers/agreementsCreator';
import AgreementActions from './DetailsTabs/AgreementActions';
import DeclineAgreementModal from './DetailsTabs/DeclineAgreementModal';
import { AddNotesInput, NotesCards } from './NotesTab';
import SingingTab from './SingingTab/SingingTab';
import { typesToSigning } from 'components/DocumentTable/DocumentTable';
import { fetchSigningState } from 'store/reducers/signingCreator';
import { resetSigningState } from 'store/slices/signingSlice';

export const sectionMatching = {
  agreement: 'document_agreement',
  sign: 'document_signing',
  note: 'documents_notes'
};

type FileDetailsModalPops = {
  isOpen: boolean;
  onCancel: () => void;
};

export default function FileDetailsModal({
  isOpen,
  onCancel,
}: FileDetailsModalPops) {
  const [isDeletingNote, setIsDeletingNote] = React.useState<boolean>(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = React.useState<boolean>(false);

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { dataUserTable } = useAppSelector((store) => store.dataUsers);
  const { selectedDocument, selectedFolder, dataDocumentTable } = useAppSelector((state) => state.documents);
  const { isOpenNotesDeleteModal, selectedNote, notes } = useAppSelector((state) => state.notes);
  const { userPermissions } = useAppSelector((store) => store.userData);
  const { agreementInfo, defaultActiveAgreementKey } = useAppSelector((state) => state.agreements);

  const documentLogs = useAppSelector((state) => state.logs.document);
  
  const selectedEntity = selectedFolder || selectedDocument;
  const type = availableIconFormat.includes(selectedEntity?.extension || '') ? selectedEntity?.extension : 'default';
  const icon = type as keyof typeof documnentIcons;

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const locale = i18n.language || 'en'

  React.useEffect(() => {
    if (selectedDocument) {
      dispatch(fetchAvailableDocumentsLogs({ document_id: selectedDocument.id, locale }));
    }
  }, [selectedDocument, locale]);

  React.useEffect(() => {
    dataRoom && !dataUserTable[0] && selectedEntity && dispatch(fetchGroupsAndUsers({room_id: dataRoom?.id}));
    dispatch(resetAgreementState());
    dispatch(resetSigningState());    
    selectedEntity && dispatch(fetchAgreements({id: selectedEntity?.id!, entity: selectedFolder ? 'folder' : 'document'}));    
    selectedEntity && selectedEntity?.signing_status !== null && dispatch(fetchSigningState({id: selectedEntity?.id!}));
  },[selectedFolder?.id, selectedDocument?.id]);

  const onDeleteNote = (id: string) => {
    dispatch(setIsOpenNotesDeleteModal(true));
    dispatch(setSelectedNote(id));
  };

  const onDeleteCancel = () => {
    dispatch(setIsOpenNotesDeleteModal(false));
    dispatch(setSelectedNote(''));
  };

  const onDeclineOpen = () => setIsDeclineModalOpen(true);
  const onDeclineCancel = () => setIsDeclineModalOpen(false);

  const onDeleteSubmit = async () => {
    setIsDeletingNote(true);
    const currentNote = notes?.filter((note) => note.id === selectedNote)[0];
    
    const textToPopup = currentNote?.text && currentNote?.text.length > 50 ? currentNote?.text.slice(0, 50) + '...' : currentNote?.text 

    try {
      await api.deleteNote(selectedNote);
      const newNotes = notes?.filter((note) => note.id !== selectedNote);
      dispatch(setNotes(newNotes || []));
      message.success(
        `${t('Documents.success.deleteNote.before')} "${textToPopup}"
        ${t('Documents.success.deleteNote.after')}`
      );
    } catch (err) {
      message.error(
        `${t('Documents.error.deleteNote.before')} "${textToPopup}"
        ${t('Documents.error.deleteNote.after')}`
      );
    } finally {
      setIsDeletingNote(false);
      dispatch(setIsOpenNotesDeleteModal(false));
    }
  };  

  const documentPermissions = dataDocumentTable.find(({ id }) => id === selectedDocument?.id)?.permissions!;
  const TabsData: TabsProps['items'] = [];

  selectedDocument && TabsData.push({
    key: 'documents_notes',
    label: t('Documents.details.notes.name'),
    children: (
      <>
        <div className={classes.notesWrap}>
          <NotesCards onDeleteClick={onDeleteNote} t={t} />
        </div>
        <div>
          <AddNotesInput
            documentId={selectedDocument?.id!}
            documentName={selectedDocument?.name!}
          />
        </div>
      </>
    ),
  },{
    key: 'documents_timelines',
    label: t('Documents.details.timeline'),
    children: (
      <div className={classes.simpleWrap}>
        <TimeLine
          logs={documentLogs.damp}
          loading={documentLogs.laoding}
          logsError={documentLogs.error}
          mode={'alternate'}
        />
      </div>
    )
  });

  TabsData.push({
    key: 'document_agreement',
    label: t('Documents.details.agreement.agreement'),
    disabled: !userPermissions.can_edit_agreement,
    children: (
      <div className={cn(classes.simpleWrap, classes.agreemensBlocks)}>
        <span className={classes.subTitle}>{t('Documents.details.agreement.agreementSetting')}</span>
        {userPermissions?.can_edit_agreement 
          && <AgreementCard
            t={t}
            selectedDocument={selectedDocument}
            selectedFolder={selectedFolder}
            documentPermissions={documentPermissions}
          />
        }
        
        <Divider />
        <span className={classes.subTitle}>{t('Documents.details.agreement.agreementControl')}</span>
        <AgreementActions onDeclineOpen={onDeclineOpen} documentPermissions={documentPermissions} />

        <Divider />

        <div className={classes.agreement_footer}>
          {agreementInfo?.comment && (
            <div className={classes.messages}>
              <div className={classes.messageTitle}>{t('Documents.details.agreement.agreementDeclineMessage')}</div>
              <AgreementMessages info={agreementInfo} />
            </div>
          )}

          
          <AgreementUsers />
          {/* <div className={classes.table}>
            <span className={classes.subTitle}>{t('Documents.details.agreement.agreementState')}</span>
          </div> */}
        </div>
        
      </div>
    )
  });

  userPermissions.can_manage_signing && typesToSigning.includes(selectedDocument?.extension!) && TabsData.push({
    key: 'document_signing',
    label: t('Documents.details.signing.title'),
    children: <SingingTab closeDetailsModal={onCancel}/>,
  });

  const modalTitle = (
    <div>
      <span className={classes.modalTitleIcon}>{documnentIcons[icon]}</span>
      <span>{selectedEntity?.name}</span>
    </div>
  );

  return (
    <Modal
      width={'85%'}
      styles={{ body: { height: '90vh' } }}
      open={isOpen}
      onCancel={onCancel}
      centered
      footer={null}
      title={modalTitle}
      className={classes.modalDetails}
    >

      <div className={classes.tabsWrap}>
        <Tabs
          centered={true}
          defaultActiveKey={defaultActiveAgreementKey}
          items={TabsData}
        />
      </div>

      {isOpenNotesDeleteModal && (
        <DeleteModal
          isOpen={isOpenNotesDeleteModal}
          onCancel={onDeleteCancel}
          isLoading={isDeletingNote}
          submit={onDeleteSubmit}
          text={`${t('Documents.modals.notes.deleteText.before')} "${selectedDocument?.name}" 
          ${t('Documents.modals.notes.deleteText.after')}`}
          title={t('Documents.modals.notes.deleteTitle')}
        />
      )}

      {isDeclineModalOpen && (
        <DeclineAgreementModal
          t={t}
          documentPermissions={documentPermissions}
          open={isDeclineModalOpen}
          onCancel={onDeclineCancel}
          documentId={selectedDocument?.id!}
        />
      )}
    </Modal>
  )
}
