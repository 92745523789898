import React from 'react';
import classes from '../SingingTab.module.scss';
import { useAppSelector } from 'store/hook';
import { Button, Card, Dropdown, MenuProps, message, Table } from 'antd';
import { getColumnsCertificate, IUserSigning } from './columnsCertificate';
import { useTranslation } from 'react-i18next';
import { IColumn, ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import { iconCases, IIconCases } from '../../DetailsTabs/AgreementUsers';
import { CloseCircleOutlined, DownloadOutlined, EllipsisOutlined, PrinterOutlined } from '@ant-design/icons';
import { pdfHelper } from 'helpers/pdfHelper';
import dayjs from 'dayjs';


type SigningCertificateProps = {
  deleteSigning: () => Promise<void>;
}

const SigningCertificate = ({deleteSigning}: SigningCertificateProps) => {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);
  const [downloadProcess, setDownloadProcess] = React.useState<string[]>([]);
  const [deleting, setDeleting] = React.useState(false);

  const { signing, isSigningLoading, signingInfo, needSigningFromUsers } = useAppSelector((state) => state.signing);
  const { users, isGroupLoading } = useAppSelector((store) => store.dataUsers);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { selectedDocument } = useAppSelector((store) => store.documents);
  const { userData } = useAppSelector((store) => store.userData);

  const { t } = useTranslation();

  const isDisabledDelete = React.useMemo(() => {
    const usersWhoParticipated = needSigningFromUsers.filter(user => user.signing_status !== null);    
    return Boolean(usersWhoParticipated.length);
  }, [needSigningFromUsers]);

  const usersWithGroups = signing?.reduce((acc: IUserSigning[], currentGroup: ISimpleGroupForAprove) => {
    if (currentGroup.users) {
      const usersWithGroupAndFullName = currentGroup.users.map(user =>{
        const currentUser = users.find(curr => curr.email === user.user_email)
        return {
          ...user,
          user_group: currentGroup.group_name,
          user_name: `${user.user_name} ${currentUser?.last_name} (${currentUser?.email})`,
          key: user.user_email,
          room: dataRoom?.name,
          document_name: selectedDocument?.name,
        }
      })
      acc = [...acc, ...usersWithGroupAndFullName];
    }
    return acc;
  }, []);  

  React.useEffect(()=> {
    const certificateColumns = getColumnsCertificate(t);
    const customColumns = certificateColumns.map((col: IColumn) => ({
      ...col, 
      render: (value: string, info: ISimpleUserForAprove) => renderCell({value, info, col}),
    }));
    setModifiedColumns(customColumns);
  },[isGroupLoading, isSigningLoading]);

  const offset = new Date().getTimezoneOffset() * 60 * 1000;
  const utcZone = new Date().getTimezoneOffset() / -60;
  const symbol = Math.sign(utcZone) === -1 ? '' : '+';

  const getFullDate = (timestamp: string) => {
    const utcDate = dayjs(timestamp).format('DD.MM.YYYY, HH:mm:ss');
    const inSeconds = dayjs(timestamp).valueOf();
    const localDate = dayjs(inSeconds - offset).format('DD.MM.YYYY, HH:mm:ss');
    return timestamp ? `${localDate} UTC${symbol}${utcZone} (${utcDate} UTC+0)` : '-'
  }
  
  const renderCell = (props: any) => {
    const { value, info, col } = props;
    if (col.key === 'signing_status') {
      return iconCases(value as IIconCases);
    }

    if (col.key === 'signing_date') {
      return getFullDate(value);
    }
    return value;
  };

  const deleteTCurrentSigning = async () => {
    setDeleting(true);
    await deleteSigning()
    setDeleting(false);
  }

  const getReport = async (type: string) => {
    setDownloadProcess(prev => [...prev, type]);
    // api.logPrint(dataRoom?.id!, false);

    const statusLocalized = {
      true: t('Documents.details.signing.userSigned'),
      false: t('Documents.details.signing.userDeclinedSigned'),
      null: t('Documents.details.signing.userNotSigned'),
    };
    
    try {
      const certificateColumns = getColumnsCertificate(t);      
      const usersWithGroupsWithRightData = usersWithGroups.map(row => ({
        ...row,
        signing_date: row.signing_date ? getFullDate(String(row.signing_date)) : '-',
        signing_status: statusLocalized[String(row.signing_status) as keyof typeof statusLocalized]
      }))
      const currentTime =  dayjs(Date.now()).format('HH:mm DD-MM-YYYY');
      const currentTimeInUTC = dayjs(Date.now()).utc().format('HH:mm DD-MM-YYYY');
      const offset = - new Date().getTimezoneOffset()/60;
      // const nameOfTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      pdfHelper<IUserSigning>(
        usersWithGroupsWithRightData,
        certificateColumns,
        `${t('Documents.details.signing.sertificatTitle')}. TazaDeal - ${currentTime} UTC+${offset} (${currentTimeInUTC} UTC+0)`,
        type
      );      
    } catch (e) {
      message.error(t(`Documents.details.signing.${type}Error`));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === type));
    }
  };

  const actionItems: MenuProps['items'] = [
    {
      key: 'export',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('export')}
          onClick={() => getReport('export')}
          disabled={ Boolean(downloadProcess[0]) }
          icon={<DownloadOutlined />}
          className={classes.liButton}
        >
          {t('Documents.details.signing.download')}
        </Button>
      ),
    },
    {
      key: 'print',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('print')}
          onClick={() => getReport('print')}
          disabled={Boolean(downloadProcess[0])}
          icon={<PrinterOutlined />}
          className={classes.liButton}
        >
          {t('Documents.details.signing.print')}
        </Button>
      ),
    },
  ];
 
  return (
    <div className={classes.table}>
      <div className={classes.tableTitleWrap}>
        <div></div>
        <div className={classes.tableTitle}>
          {t('Documents.details.signing.sertificatTitle')}
        </div>
        <div>
          {!isDisabledDelete && userData?.id === signingInfo?.initiator_id && <Button
            danger
            loading={deleting}
            onClick={deleteTCurrentSigning}
          >
            {t('Documents.details.signing.deleteButton')}
          </Button>}
          <Dropdown
            open={isOpenDropdown || Boolean(downloadProcess[0])}
            onOpenChange={(open) => setIsOpenDropdown(open)}
            menu={{ items: actionItems }}
            placement='bottomLeft'
          >
            <Button style={{marginLeft: 6}} icon={<EllipsisOutlined />} />
          </Dropdown>

        </div>
      </div>
      {signing[0] && <Table
        columns={modifiedColumns}
        dataSource={usersWithGroups}
        pagination={false}
        loading={isSigningLoading || isGroupLoading}
        scroll={{ y: signingInfo?.comment ? 'calc(90vh - 345px)' : 'calc(90vh - 204px)'}}
      />}

      {signingInfo?.comment && (<>
        <div
          className={classes.tableTitle}
        >
          {t('Documents.details.agreement.agreementDeclineMessage')}
        </div>
        <div>
            <div className={classes.messages_overlay}>
              <Card className={classes.messages_card} key={`message-${signingInfo.id}`} bordered>
                <span style={{ paddingRight: 10, fontSize: 16 }}><b><i>
                  <CloseCircleOutlined style={{ color: 'red', paddingRight: 5 }} />{signingInfo?.author?.name}:
                </i></b></span>
                <span>{signingInfo?.comment}</span>
              </Card>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SigningCertificate;
