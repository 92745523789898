import { message, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import TableLoader from 'components/Sceletons/TableLoader';
import classes from '../../Billing.module.scss';
import api from 'api';
import { IColumn } from 'interfaces';
import { getRoomsBillingColumns } from './collumn';
import { Link } from 'react-router-dom';

const RoomsBillingTable = () => {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const { userData } = useAppSelector((store) => store.userData);
  const { i18n, t } = useTranslation();

  React.useEffect(() => {
      getBills();
  }, []);

  const getBills = async () => {
      setIsLoading(true);
      try {
          const response = await api.getRoomsBills();          
          setDataTable(response.data.map((el: any) => ({...el, key: el.id})));
      } catch (e: any) {
          message.error(t('Billing.error.failedGet'));
      } finally {
          setIsLoading(false);
      }
  };

  React.useEffect(()=> {
    const roomsBillingColumns = getRoomsBillingColumns(t);
    const customColumns = roomsBillingColumns.map((col: IColumn
    ) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[dataTable, i18n.language]);


  const renderCell = ({ value, info, col }: any) => {
    if (col.key === 'created_at' || col.key === 'purchase_date') {
        return value && getDateWithTimeZone(value, userData?.date_format! || 'international');
    }
    if (col.key === 'paid_period') {
      return info.duration && info.duration_unit
        ? `${info.duration} ${t(`Billing.roomsColumns.${info.duration_unit.toLowerCase()}`)}`
        : '-';
    }
    if (col.key === 'cost') {
      return `${value} $`;
    }
    if (col.key === 'payment_status') {
      return value === 'awaiting_payment' 
        ? <Link to={info.payment_link}>{ t(`Billing.columns.${value}`) }</Link>
        : <span style={{color: 'lightgreen'}}>{t(`Billing.columns.${value}`)}</span>
    }

    if(col.key === 'tariff_name') {
      return value ? <div className={classes.tariff_name}>
        {t(`Tariffs.${col.key}.${value}`)}
      </div> : '-'
    };

    return value;
  };

  if (isLoading) {
    return (
      <div className={classes.scheduleWrap}>
        <TableLoader height={'calc(100vh - 165px)'} padding={15} />
      </div>
    );
  }

  return <Table
    dataSource={dataTable}
    columns={modifiedColumns}
    pagination={false}
    size='middle'
    className={classes.scheduleTable}
    scroll={{
      y: 'calc(100vh - 220px)',
    }}
  />
}

export default RoomsBillingTable;
