import { chatDataType, IChat, IChatMessage, messageStatusType } from 'interfaces';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRepliedMessage } from 'interfaces/IChatMessage';
import fetchChatData, {
  createChat,
  createGLobalChat,
  fetchChatHistory,
  fetchChatId,
  fetchChatInfo,
  fetchGlobalChats,
  setRejectMessages,
} from 'store/reducers/chatCreator';

type chatStatusType = 'pending' | 'send';

type chatMessageType = {
  message: string;
  id: string;
  status: messageStatusType;
  replied_to?: IRepliedMessage | null;
};

type initialStateType = {
  chatData: chatDataType;
  chatStatus: chatStatusType;
  chatMessage: chatMessageType;
  roomAllChats: IChat[];
  globalChats: IChat[];
  selectedChat: IChat | null;
  isLoadedSession: boolean;
  roomChatLoading: boolean;
  globalChatLoading: boolean;
  currnetChatLoading: boolean;
  isOpenCreateModal: boolean;
  listStatus: 'main' | 'groupList' | 'room';
  checkingMessages: IChatMessage[];
  viewsMessagesIds: string[];
  attemptCount: number;

  messageForReply: IRepliedMessage | null;
};

const initialState: initialStateType = {
  chatData: {},
  chatStatus: 'pending',
  chatMessage: {
    id: '',
    status: 'pending',
    message: '',
    replied_to: null
  },
  selectedChat: null,
  roomAllChats: [],
  globalChats: [],
  checkingMessages: [],
  isLoadedSession: false,
  currnetChatLoading: false,
  roomChatLoading: false,
  globalChatLoading: true,
  isOpenCreateModal: false,
  viewsMessagesIds: [],
  attemptCount: 0,
  listStatus: 'room',

  messageForReply: null
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatData: (state, action: PayloadAction<chatDataType>) => {
      state.chatData = action.payload;
    },

    setChatStatus: (state, action: PayloadAction<chatStatusType>) => {
      state.chatStatus = action.payload;
    },

    setChatMessage: (state, action: PayloadAction<chatMessageType>) => {
      state.chatMessage = action.payload;
    },

    setIsLoadedSession: (state, action: PayloadAction<boolean>) => {
      state.isLoadedSession = action.payload;
    },

    setAttemptCount: (state, action: PayloadAction<number>) => {
      state.attemptCount = action.payload;
    },

    setCheckingMessages: (state, action: PayloadAction<IChatMessage[]>) => {
      state.checkingMessages = action.payload;
    },

    setSelectedChatRoom: (state, action: PayloadAction<IChat | null>) => {
      state.selectedChat = action.payload ? { ...action.payload, unviewed_amount: 0 } : action.payload;
    },

    setUnviewedAmount: (state, action: PayloadAction<{ count: number; chat_id: string }>) => {
      state.roomAllChats = state.roomAllChats.map((chat) =>
        chat.id === action.payload.chat_id
          ? {
              ...chat,
              unviewed_amount:
                (action.payload.count < 0 ? -chat.unviewed_amount : action.payload.count) + chat.unviewed_amount,
            }
          : chat
      );
    },

    setCurrentChatLoading: (state, action: PayloadAction<boolean>) => {
      state.currnetChatLoading = action.payload;
    },

    setRoomAllChats: (state, action: PayloadAction<IChat[]>) => {
      const chatsStore: chatDataType = {};

      action.payload.forEach((el, idx) => {
        chatsStore[el.id] = state.chatData[el.id];
      });

      state.roomAllChats = action.payload;
      state.chatData = chatsStore;
    },

    clearAllChatData: (state) => {
      state.roomAllChats = [];
      state.chatData = {};
      state.selectedChat = null;
    },

    setIsOpenCreateModal: (state, action) => {
      state.isOpenCreateModal = action.payload;
    },

    setListStatus: (state, action: PayloadAction<'main' | 'groupList' | 'room'>) => {
      state.listStatus = action.payload;
    },

    setViewsMessagesIds: (state, action: PayloadAction<string[]>) => {
      state.viewsMessagesIds = action.payload;
    },
    setMessageForReply: (state, action: PayloadAction<any | null>) => {
      state.messageForReply = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchChatData.fulfilled,
      (state, action: PayloadAction<{ data: IChatMessage[]; grouped: IChatMessage[][]; chatId: string }>) => {
        state.chatData = {
          ...state.chatData,
          [action.payload.chatId]: {
            data: action.payload.data,
            grouped: action.payload.grouped,
            isLoadedHistory: true,
          },
        };
      }
    );

    builder.addCase(createChat.pending, (state) => {
      state.roomChatLoading = true;
    });

    builder.addCase(createChat.rejected, (state) => {
      state.roomChatLoading = false;
    });

    builder.addCase(createChat.fulfilled, (state, action: PayloadAction<{ chat: IChat; isForPush: boolean }>) => {
      action.payload.isForPush && state.roomAllChats.push(action.payload.chat);
      state.selectedChat = action.payload.chat;
      state.roomChatLoading = false;
      if (!state.chatData[action.payload.chat?.id] || !state.chatData[action.payload.chat?.id]?.isLoadedHistory) {
        state.chatData[action.payload.chat.id] = {
          data: [],
          grouped: [],
          isLoadedHistory: false,
        };
      }
    });

    builder.addCase(createGLobalChat.pending, (state) => {
      state.globalChatLoading = true;
    });

    builder.addCase(createGLobalChat.rejected, (state) => {
      state.globalChatLoading = false;
    });

    builder.addCase(createGLobalChat.fulfilled, (state, action: PayloadAction<{ chat: IChat; isForPush: boolean }>) => {
      action.payload.isForPush && state.globalChats.push(action.payload.chat);
      state.selectedChat = action.payload.chat;
      state.globalChatLoading = false;

      if (!state.chatData[action.payload.chat?.id] || !state.chatData[action.payload.chat?.id]?.isLoadedHistory) {
        state.chatData[action.payload.chat.id] = {
          data: [],
          grouped: [],
          isLoadedHistory: false,
        };
      }
    });

    builder.addCase(fetchChatId.pending, (state) => {
      state.roomChatLoading = true;
    });

    builder.addCase(
      fetchChatId.fulfilled,
      (
        state,
        action: PayloadAction<{
          chats: IChat[];
          selectedChat: IChat | null;
        }>
      ) => {
        const chatsStore: chatDataType = { ...state.chatData };

        if (!action.payload.selectedChat) return;

        action.payload.chats.forEach((el, idx) => {
          if (el.id === action.payload.selectedChat?.id) return;

          chatsStore[el.id] = {
            data: [],
            grouped: [],
            isLoadedHistory: false,
          };
        });

        state.roomAllChats = action.payload.chats;
        state.selectedChat = action.payload.selectedChat;
        state.roomChatLoading = false;
        state.chatData = chatsStore;
      }
    );

    builder.addCase(fetchChatId.rejected, (state) => {
      state.roomChatLoading = false;
    });

    builder.addCase(fetchGlobalChats.pending, (state) => {
      state.globalChatLoading = true;
    });

    builder.addCase(
      fetchGlobalChats.fulfilled,
      (
        state,
        action: PayloadAction<{
          chats: IChat[];
          selectedChat: IChat;
          checkingMessages: IChatMessage[];
        }>
      ) => {
        const chatsStore: chatDataType = { ...state.chatData };
        state.checkingMessages = action.payload.checkingMessages;

        action.payload.chats.forEach((el, idx) => {
          if (el.id === action.payload.selectedChat.id) return;
          if (chatsStore[el.id]?.data?.length) return;

          chatsStore[el.id] = {
            data: [],
            grouped: [],
            isLoadedHistory: false,
          };
        });

        state.globalChats = action.payload.chats;
        state.selectedChat = action.payload.selectedChat;
        state.globalChatLoading = false;
        state.chatData = chatsStore;
      }
    );

    builder.addCase(fetchGlobalChats.rejected, (state) => {
      state.globalChatLoading = false;
    });

    builder.addCase(fetchChatHistory.pending, (state) => {
      state.currnetChatLoading = true;
    });

    builder.addCase(
      fetchChatHistory.fulfilled,
      (
        state,
        action: PayloadAction<{
          chat_id: string;
          history: IChatMessage[];
          splittedHistory: IChatMessage[][];
        }>
      ) => {
        const { chat_id, history, splittedHistory } = action.payload;
        state.chatData[chat_id] = {
          data: history,
          grouped: splittedHistory,
          isLoadedHistory: true,
        };
        state.currnetChatLoading = false;
      }
    );

    builder.addCase(fetchChatInfo.fulfilled, (state, action: PayloadAction<IChat>) => {
      !state.chatData[action.payload.id] && state.roomAllChats.push(action.payload);
      state.chatData[action.payload.id] = {
        grouped: [],
        data: [],
        isLoadedHistory: false,
      };
    });

    builder.addCase(
      setRejectMessages.fulfilled,
      (
        state,
        action: PayloadAction<{
          chatData: chatDataType;
          allRejectedMessages: string[][];
          rejectedMessages: IChatMessage[];
          isShowSupport: boolean;
        }>
      ) => {
        const {
          allRejectedMessages: [roomRejectedMessages, mainRejectedMessages],
        } = action.payload;
        state.chatData = action.payload.chatData;
        state.checkingMessages = action.payload.rejectedMessages;

        state.roomAllChats = state.roomAllChats.map((chat) =>
          roomRejectedMessages.includes(chat.id) ? { ...chat, rejected_amount: true } : chat
        );

        if (mainRejectedMessages.length) {
          state.globalChats = state.globalChats.map((chat) =>
            mainRejectedMessages.includes(chat.id) ? { ...chat, rejected_amount: true } : chat
          );
        }
      }
    );
  },
});

const chatReducer = chatSlice.reducer;
export const {
  setChatData,
  setChatStatus,
  setChatMessage,
  setIsLoadedSession,
  setSelectedChatRoom,
  setCurrentChatLoading,
  clearAllChatData,
  setRoomAllChats,
  setIsOpenCreateModal,
  setUnviewedAmount,
  setListStatus,
  setCheckingMessages,
  setViewsMessagesIds,
  setAttemptCount,
  setMessageForReply,
} = chatSlice.actions;
export default chatReducer;
