import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPathToDocument, getTableFile, getPathToImage, getPathToVideo } from 'helpers/getPathToDocument';
import { IDocumentInfo, ILocale} from 'interfaces';

export const imageExtensions = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'jfif'];
export const videoExtensions = ['mp4', 'mov', 'webm', 'ogg'];

const fetchDocumentToViewer = createAsyncThunk(
  'viewer/getDocument',  
  async (payload: IDocumentInfo & ILocale, thunkApi) => {
    try {
      let file: any;
      if (payload?.extension  === 'xlsx') {
        file = await getTableFile(payload);
      } else if (imageExtensions.includes(payload?.extension!)) {
        file = await getPathToImage(payload);
      } else if (videoExtensions.includes(payload?.extension!)) {
        file = await getPathToVideo(payload);
      } else {
        file = await getPathToDocument({ ...payload, locale: payload.locale });
      }      
      return {...file, extension: payload?.extension};
    } catch (err: any) {
      return thunkApi.rejectWithValue('Не удалось получить документ');
    }
  }
);

export default fetchDocumentToViewer;
