import { WarningOutlined } from '@ant-design/icons';
import React from 'react';
import classes from '../SingingTab.module.scss';
import { useTranslation } from 'react-i18next';

const SignedEarlier = () => {
  const { t } = useTranslation();

  return (
    <div style={{paddingTop: 20, alignItems: 'center'}} className={classes.signingCard}>
      <WarningOutlined  style={{fontSize: 54, color: 'orange'}}/>
      <div className={classes.signingCardTilte}>{t('Documents.details.signing.signedEarlier')}</div>
      <div className={classes.signingCardTilte}>{t('Documents.details.signing.signedEarlierDetails')}</div>
    </div>
  );
}

export default SignedEarlier;
