import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { LikeOutlined } from '@ant-design/icons';
import classes from '../Login.module.scss';

type ConfirmEmailPops = {
  isOpen: boolean;
  onCancel: () => void;
  email?: string | null;
};

const ConfirmEmail = ({ isOpen, onCancel, email }: ConfirmEmailPops) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      width={'60%'}
      onCancel={onCancel}
      onOk={onCancel}
      centered
      cancelButtonProps={{style: {display: 'none'}}}
    >
      <div className={classes.confirmWrap}>
        <LikeOutlined className={classes.likeIcon}/>
        <div className={classes.textConfirm}>{`${t('SignUp.success')} - ${email}`}</div>
      </div>
    </Modal>
  );
};

export default ConfirmEmail;
