import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './OrganizationSchedule.module.scss'
import Schedule from './Schedule'
import Requests from './Requests';
import Subscriptions from './Subscriptions';
import Visitors from './Visitors';

const OrganizationIntegrations = () => {

  const { t } = useTranslation();
  
  const TabsData: TabsProps['items'] = [
    {
      key: 'schedule',
      label: t('Organizations.tabs.schedule'),
      children: <Schedule />,
    },
    {
      key: 'requests',
      label: t('Organizations.tabs.requests'),
      children: <Requests />,
    },
    {
      key: 'subscriptions',
      label: t('Organizations.tabs.subscriptions'),
      children: <Subscriptions />,
    },
    {
      key: 'visitors',
      label: t('Organizations.tabs.visitors'),
      children: <Visitors />,
    },
  ];

  return (
    <div className={classes.tabsWrap}>
      <Tabs
        className={classes.tabs}
        defaultActiveKey={'schedule'}
        items={TabsData}
        type='card'
      />
    </div>
  )
}

export default OrganizationIntegrations
