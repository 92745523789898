import { requestTemplate } from 'api';
import { CancelToken } from 'axios';
import { IDetails, IDocument, IFolder, IResearchResponce } from 'interfaces';

type DeleteResponce = {
  success: boolean,
  detail: string,
}

type DocumentsPayload = {
  search?: string;
  folder_id?: string;
  room_id: string;
  action: string;
}

//DOCUMENTS
const getDocuments = (payload: DocumentsPayload, sourceToken?: CancelToken) =>
  requestTemplate.get<IDocument[]>(`/documents/`, {
    cancelToken: sourceToken,
    params: payload,
  });

// const getDocumentsOfFolder = (
//   idRoom: string, idFolder: string, sourceToken?: CancelToken
// ) => requestTemplate.get<IDocument[]>(`/documents/?room_id=${idRoom}&folder_id=${idFolder}&id=${idRoom}&action=view`, {
//   cancelToken: sourceToken,
// });

const changeAgreementStatus = <T = any> (
  payload: {id: string, entity: string}, room_id: string, entity_id: string
) => requestTemplate.put<T>(`/documents/agreement/?action=update&id=${room_id}&entity=${entity_id}`, payload);

const uploadFile = (
  payload: { formData: FormData, id: string, name: string, action: string },
) => requestTemplate.post<IDocument>(`/documents/upload/?action=${payload.action}&id=${payload.id}&document_name=${payload.name}`, payload.formData, {
   headers: { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' }
});

const deleteDocuments = (
  payload: string[], logInfo: IDetails
) => requestTemplate.delete<DeleteResponce>(`/documents/?action=delete&id=${logInfo.room_id}`,  { data: {documents_ids: payload, details: logInfo.details} });

const getDocumentById = (document_id: string) => requestTemplate.get<IDocument>(`/documents/by_id/?document_id=${document_id}`);

const getFolderById = (folder_id: string, room_id: string) => requestTemplate.get<IFolder>(`/folders/by_id/?folder_id=${folder_id}&room_id=${room_id}`);

const downloadFile = (payload: any) => requestTemplate.get<Blob>(`/documents/download/`, { responseType: 'blob', params: payload });

const viewFile = (payload: any) => requestTemplate.get<Blob>(`/documents/view/`, { responseType: 'blob', params: payload });

const getDocumentsLink = (token: string) => requestTemplate.get<string>(`/documents/link/?token=${token}`);

const researchDocument = (payload: FormData) =>
  requestTemplate.post<IResearchResponce>('/documents/research/', payload, {
    headers: { Accept: 'application/json', 'Content-Type': 'multipart/form-data' },
  });
  
const declineAgreement = (payload: { comment: string; id: string; entity: string }) =>
  requestTemplate.post('/documents/agreement/decline/', payload);

const downloadAsArchive = (payload: string) => requestTemplate.get(`/documents/download_archive/?room_id=${payload}`, {
  responseType: 'blob',
});

const documents = { 
  getDocuments,
  // getDocumentsOfFolder,
  changeAgreementStatus,
  uploadFile,
  deleteDocuments,
  downloadFile,
  viewFile,
  getDocumentsLink,
  getDocumentById,
  getFolderById,
  researchDocument,
  declineAgreement,
  downloadAsArchive
};

export default documents;
