import React from 'react';
import cn from 'classnames'
import classes from './Mobile.module.scss'
import { useNavigate } from 'react-router-dom';

const Mobile = () => {

  const navigate = useNavigate();

  React.useEffect(() => {
    const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)
    if (!isMobile) navigate('/');
  }, []);

  return (
    <div className={classes.wrapper}>
        <div className={classes.headerImg}>TazaDeal</div>
        <div className={cn(classes.text, classes.title)}>Уважаемый пользователь!</div>
        <div className={classes.text}>Браузерная версия сервиса не поддерживается на мобильных устройствах</div>
        <div className={classes.text}>Для корректной работы TazaDeal установите приложение</div>
        <div className={classes.linkWrap}>
            <div className={cn(classes.link, classes.apple)} />
            <div className={cn(classes.link, classes.google)} />
        </div>
    </div>
  )
}

export default Mobile
