import React from 'react';
import classes from './TariffEnded.module.scss';
import { DownloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getFile } from 'helpers/downloadSomeFile';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchRoom } from 'store/reducers/roomsCreator';

const TariffEnded = () => {
  const [isDownloading, setIsDownloadLoading] = React.useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { dataRoom, isLoadingRooms } = useAppSelector((store) => store.dataRoom);  
  const { isLoadingUserData } = useAppSelector((store) => store.userData);  

  React.useEffect(() => {
    params.room && dispatch(fetchRoom({ id: params.room }));
  }, [])
  
  React.useEffect(() => {
    if (!dataRoom?.lives_to) {      
      dataRoom && navigate(`/room/${dataRoom?.id}/documents`);
    }
  }, [dataRoom])

  const downloadDocumentsOfRoom = async () => {
    setIsDownloadLoading(true)

    try {
      const response = await api.downloadAsArchive(dataRoom?.id!);
      getFile(response.data, `documents_of_room(${dataRoom?.name})`);

      message.success(t('ended_tariff_lifetime.successDownload'));
    } catch (e) {
      message.error(t('ended_tariff_lifetime.errorDownload'));
    } finally {
      setIsDownloadLoading(false);
    }
  }

  const navigateToTariffs = () => {
    navigate(`/tariffs`);
    dataRoom?.id && localStorage.setItem('ended_tariff_lifetime', dataRoom?.id);
  }

  return (
    <div className={classes.infoWrap}>
      <div className={classes.info}>
        <WarningOutlined className={classes.warningIcon}/>
        <div className={classes.text}>
          <p><b>{dataRoom?.name}:</b> {t('ended_tariff_lifetime.accessLimited')}{}</p>
          <p>{t('ended_tariff_lifetime.deletionWarning')}</p>
          <p>{t('ended_tariff_lifetime.paymentOffer')}</p>
        </div>
        <div className={classes.actions}>
          <Tooltip title={t('ended_tariff_lifetime.notAvailable')}>
            <Button 
              disabled //={isLoadingRooms || isLoadingUserData || !dataRoom || isDownloading}
              loading={isLoadingRooms || isLoadingUserData || !dataRoom || isDownloading}
              type='primary'
              onClick={downloadDocumentsOfRoom}
              className={classes.downloadBtn}
              icon={<DownloadOutlined className={classes.downloadIcon}/>}
            >
              {t('ended_tariff_lifetime.downloadBtn')} 
            </Button>
          </Tooltip>
          <div onClick={navigateToTariffs} className={classes.primary}>
            {t('ended_tariff_lifetime.tarrifsLink')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TariffEnded;
