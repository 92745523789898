import api from 'api';
import { socketUrl } from 'api/ws';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchChatData, { fetchChatInfo, fetchUnviewedAmount } from 'store/reducers/chatCreator';
import fetchStatusDocuments, {
  fetchDeleteDocument,
  fetchDeleteFolder,
  fetchDocumentMove,
  fetchDocumentUpdate,
  fetchFoldersPermissionsUpdate,
} from 'store/reducers/documentsSocketsCreator';
import { fetchRoomNotifications } from 'store/reducers/roomsCreator';
import { setChatMessage, setChatStatus, setIsLoadedSession } from 'store/slices/chatsSlice';
import { setNeedToUpdateRooms } from 'store/slices/dataRoomSlice';
import { SocketIO } from 'utils/Socket';
import { setCookie } from 'helpers/cookies'
import { setIsOpenSuccessfulPayment } from 'store/slices/windowStateSlice';

export default function SessionComponent() {
  const [isPausedWS, setIsPausedWS] = React.useState(false);
  const socketRef = React.useRef<SocketIO | null>(null);

  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const locale = i18n.language || 'en';

  const { userData } = useAppSelector((store) => store.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { chatStatus, chatMessage, selectedChat, isLoadedSession } = useAppSelector((store) => store.chat);

  const chatCases = {
    pending: (message: any) => {},
    send: (message: any) => {
      socketRef.current?.send('chat', message);
      dispatch(setChatStatus('pending'));
      dispatch(setChatMessage({ id: '', status: 'pending', message: '', replied_to: null }));
    },
  };

  React.useEffect(() => {
    if (!isPausedWS) {
      socketRef.current = new SocketIO();
      socketRef.current.connect(socketUrl);
      socketRef.current.on('connect', () => {
        setCookie('sid', socketRef.current?.socket?.id || '', 1)
        console.log(`The session is open at: ${new Date().toLocaleString()}`);
      });

      socketRef.current.send('auth:post', { user_id: userData?.id });

      socketRef.current.on('auth:get', () => {
        dispatch(setIsLoadedSession(true));
        console.log('Session is authed');
      });

      socketRef.current.on('new_chat', (message: any) => {
        console.log('new chat created -', message);
        const data = JSON.parse(message);
        dispatch(fetchChatInfo({ chatId: data.new_chat_id, ownerId: userData?.id! }));
      });

      socketRef.current.on('new_global_chat', (message: any) => {
        const data = JSON.parse(message);
        console.log('new global chat created -', data);
        dispatch(fetchChatInfo({ chatId: data.new_chat_id, ownerId: userData?.id! }));
      },)

      socketRef.current.on('document_create', async (message: any) => {
        const data = JSON.parse(message);
        const response = await api.getDocumentById(data.document_id);
        dispatch(fetchStatusDocuments({ type: 'replace', newDocuments: [response.data] }));
        console.log(`Get session message: ${message}`);
      });

      socketRef.current.on('folder_create', async (message: any) => {
        const data = JSON.parse(message);
        const response = await api.getFolderOfFolder(data.folder_id, data.room_id);
        console.log(`Get session message: ${message}`);
        dispatch(
          fetchStatusDocuments({
            type: 'replace',
            newFolder: response.data,
            parent_folder_id: data.parent_folder_id,
          })
        );
      });

      socketRef.current.on('document_delete', async (message: any) => {
        const data = JSON.parse(message);
        console.log(`Document deleted: ${message}`);
        dispatch(fetchDeleteDocument(data));
      });

      socketRef.current.on('document_move', async (message: any) => {
        const data = JSON.parse(message);
        console.log(`Document moved: ${message}`);
        dispatch(fetchDocumentMove(data));
      });

      socketRef.current.on('folder_delete', async (message: any) => {
        const data = JSON.parse(message);
        console.log(`Folder deleted: ${message}`);
        dispatch(fetchDeleteFolder(data));
      });

      socketRef.current.on('document_update', async (message: any) => {
        const data = JSON.parse(message);        
        console.log(`Document updated: ${message}`);
        dispatch(fetchDocumentUpdate(data));
      });

      socketRef.current.on('permissions_update', async (message: any) => {
        const data = JSON.parse(message);
        console.log(`Document updated: ${message}`);
        dispatch(fetchFoldersPermissionsUpdate(data));
      });

      socketRef.current.on('notification_create', async (message: any) => {
        console.log(`Notification created: ${message}`);
        dispatch(fetchRoomNotifications({ locale }));
      });

      socketRef.current.on('rooms_update', async (message: any) => {
        console.log(`Message: ${message}`);
        dispatch(setNeedToUpdateRooms(true));
      });

      socketRef.current.on('successful_payment', async (message: any) => {
        console.log('Payment info:', message);
        dispatch(setIsOpenSuccessfulPayment(true));
      });

      socketRef.current.on('chat', async (message: any) => {
        const data = JSON.parse(message);
        if (data.email !== userData?.email && data.chat_id !== selectedChat?.id) {
          dispatch(fetchUnviewedAmount({data, count: 1}));
        }
        dispatch(fetchChatData({ data, type: 'replace' }));
        console.log(`Get session message: ${message}`);
      });

      socketRef.current.on('error', (error: any) => console.log(error, 'Session error'));

      socketRef.current.on('disconnect', () => {
        setCookie('sid', '', 1)
        setIsPausedWS(true);
        console.log(`The session is closed at: ${new Date().toLocaleString()}`);
      });
    }

    return () => {
      if (socketRef.current?.socket?.connected) socketRef.current?.disconnect();
    };
  }, [isPausedWS, locale]);

  React.useEffect(() => {
    if (dataRoom?.id && socketRef.current?.socket?.connected && isLoadedSession) {
      const messageNewRoom = { room_id: dataRoom?.id };
      console.log('Send session message', messageNewRoom);
      socketRef?.current!.send('init', messageNewRoom);
    }
  }, [dataRoom?.id, socketRef.current?.socket?.connected, isLoadedSession]);

  React.useMemo(
    () =>
      document.addEventListener('click', function () {
        if (socketRef.current?.socket?.disconnected) {
          setIsPausedWS(false);
        }
      }),
    [socketRef.current?.socket?.disconnected, isPausedWS]
  );

  React.useEffect(() => {
    const data = {
      room_id: dataRoom?.id,
      message: chatMessage.message,
      chat_id: selectedChat?.id,
      created_at: chatMessage.id,
      replied_to: chatMessage.replied_to ? chatMessage.replied_to.id : null,
    };

    chatCases[chatStatus](data);
  }, [chatStatus]);

  // Функционал прерывания сессии при неактивной вкладке браузера (сворачивание браузера/переход на другую вкладку)
  // React.useMemo(() => document.addEventListener('visibilitychange', function(){
  //   if (document.hidden && !isPausedWS && sessionChanel.current?.readyState === 1){
  //     setIsPausedWS(true);
  //     // console.log(`Сlosed: ${new Date().toLocaleString()}`);
  //     // sessionChanel.current?.send(`Сlosed: ${new Date().toLocaleString()}`);
  //     sessionChanel.current?.close();
  //   } else if (!document.hidden && isPausedWS) {
  //     setIsPausedWS(false);
  //   }
  // }), [sessionChanel.current?.readyState]);

  // Ручное прерыване канала вебсокета для тестирования
  // const closeWS = () => {
  //   sessionChanel.current?.close();
  //   // console.log(`Сlosed: ${new Date().toLocaleString()}`);
  //   // setIsPausedWS(true);
  // }
  // return (
  //   !isPausedWS
  //     ? <button style={{position: 'absolute'}} onClick={closeWS}>{ 'Остановить соединение' }</button>
  //     : null
  // );

  return null;
}
