import { Button, Col, Row, Tooltip, message } from 'antd';
import { PersonalInforamtion } from 'components/ProfileInfo';
import ProfileLoader from 'components/Sceletons/ProfileLoader';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchProfileData } from 'store/reducers/profileCreator';
import classes from './Profile.module.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Profile() {
  const dispatch = useAppDispatch();
  const { profileIsLoading, profileIsError } = useAppSelector((state) => state.profile);
  const { state } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchProfileData());
  }, []);

  useEffect(() => {
    if (profileIsError) {
      message.error(profileIsError);
    }
  }, [profileIsError]);

  const onBack = () => navigate(state || '/rooms');

  return (
    <div style={{ width: '100%' }}>
      {/* <div className={classes.title}>{t('Users.HelperMenu.personalInfo')}</div> */}
      <div className={classes.profileBody}>
        <Tooltip title={t('main.backButton')}>
          <Button className={classes.backButton} onClick={onBack}>
            <ArrowLeftOutlined />
          </Button>
        </Tooltip>
        {profileIsLoading ? (
          <ProfileLoader />
        ) : (
          <Row justify={'space-evenly'}>
            <Col span={12}>
              <PersonalInforamtion />
            </Col>
            {/* <Col span={8}>
              <ProfileInformation />
            </Col> */}
          </Row>
        )}
      </div>
    </div>
  );
}
