import React from 'react';
import classes from '../SingingTab.module.scss'
import { useTranslation } from 'react-i18next';

type CardTitleProps = {
  signingCreated: boolean
}

const CardTitle = ({ signingCreated }: CardTitleProps) => {
  const { t } = useTranslation();

  return (
    <div>
      { signingCreated
        ? (
          <div className={classes.signingTilte}>{t('Documents.details.signing.signingTitle')}</div>
        )
        : (<div className={classes.signingCardTilte}>
          {t('Documents.details.signing.cardTitle')}
          {/* <div>
            {t('Documents.details.signing.cardMinCondition')}
          </div> */}
        </div>)
      }
    </div>
  );
}

export default CardTitle;
