export const availableKeysInDictionary = [
  'username',
  'action',
  'document',
  'date',
  'time',
  'entityname',
  'folder_id',
  'folder_name',
  'emails',
  'ip',
  'location',
  'permissions_before',
  'permissions_after',
  'city',
  'country',
  'group_name',
  'group_role',
  'group_id',
  'organization_name',
  'organization_description',
  'organization_link',
  'organization_id',
  'prev_group_name',
  'documents_names',
  'documents_ids',
  'document_id',
  'email',
  'phone',
  'before',
  'after',
  'document_size',
  'note_text',
  'agreement_data',
  'confidential_date',
  'browser_version',
  'old_folder_name',
  'signing_data',
];

export const keysAvailableToTranslate = [
  'name',
  'description',
  'custom_url',
  'agreement_enable',
  'agreement_file',
  'organization_name',
  'organization_description',
  'organization_link',
]
