import { removeItemsFromLocalStorage } from 'helpers/cleanLocalStorage';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const WrongAddress = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    removeItemsFromLocalStorage()
    navigate('/rooms');
  }, []);

  return (
    <main className={'errorPage'}><p>Invalid address</p></main>
  );
}

export default WrongAddress;
