
export type ITariff = {
  tariff_name: Tarrifs;
  tariff_cost: string;
  tariff_constraints: {
    storage: number;
    members: number;
  };
};

export enum Tarrifs {
  Trial = 'trial',
  Starter = 'starter',
  Business = 'business',
  Enterprise = 'enterprise',
};

export enum StorageValues {
  Hundred = '100',
  TwoHundredFifty = '250',
  fiveHundred = '500',
};

export type TarrifsCost = {
  [key in Tarrifs]?: {
    [key in StorageValues]?: number;
  } | null
};

export const tariffsCost: TarrifsCost = {
  [Tarrifs.Trial]: {
    [StorageValues.Hundred]: 0,
  },
  [Tarrifs.Starter]: {
    [StorageValues.Hundred]: 59,
    [StorageValues.TwoHundredFifty]: 69,
    [StorageValues.fiveHundred]: 79
  },
  [Tarrifs.Business]: {
    [StorageValues.fiveHundred]: 199,
  },
  [Tarrifs.Enterprise]: null,
};

export const costWithSale: TarrifsCost = {
  [Tarrifs.Trial]: {
    [StorageValues.Hundred]: 0,
  },
  [Tarrifs.Starter]: {
    [StorageValues.Hundred]: 47,
    [StorageValues.TwoHundredFifty]: 55,
    [StorageValues.fiveHundred]: 63,
  },
  [Tarrifs.Business]: {
    [StorageValues.fiveHundred]: 159,
  },
  [Tarrifs.Enterprise]: null,
};

export const tariffsData: any[] = [
  {
    key: 'tariff_name',
  },
  {
    key: 'price',
  },
  {
    key: 'number_of_users',
    trial: 10,
    starter: 100,
    business: 250,
    enterprise: 'unlimited',
  },
  {
    key: 'by_it',
  },
  {
    key: 'storage_capacity',
    trial: [10],
    starter: [100, 250, 500],
    business: [500], //[500, 1000],
    enterprise: [],
    current_capacity: {
      trial: 10,
      starter: 100,
      business: 500,
      enterprise: '',
    }
  },
  {
    key: 'suitable_for',
  },
  {
    key: 'available_rooms',
  },
  {
    key: 'preconfigured_roles'
  },
  {
    key: 'access_period',
  },
  {
    key: 'number_of_admins',
  },
  {
    key: 'bulk_mailing',
  },
  {
    key: 'nda_opportunity',
  },
  {
    key: '2FA',
  },
  {
    key: 'SSO',
  },
  {
    key: 'user_activity_log'
  },
  {
    key: 'water_marks',
  },
  {
    key: 'document_encryption',
  },
  {
    key: 'documents_approval',
  },
  {
    key: 'documents_analytics',
  },
  {
    key: 'chat_rooms',
  },
  {
    key: 'backup'
  },
  {
    key: 'storage_after_payment',
  },
  {
    key: 'electronic_signature'
  },
  {
    key: 'additional_storage',
  },
  {
    key: 'uploading_data'
  },
  {
    key: 'mobile_application',
  },
  {
    key: 'by_it_2',
  },
];
