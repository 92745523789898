import { Form, Input, message, Modal, Statistic, Typography } from 'antd';
import auth from 'api/auth';
import axios, { CancelTokenSource } from 'axios';
import { TFunction } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import classes from './TFAuthModal.module.scss';
import { setCookie } from 'helpers/cookies';
import { useNavigate } from 'react-router-dom';
import { getBrowserClientData } from 'helpers/browserData/browserData';
import api from 'api';

type Props = {
  isOpen: boolean;
  email: string;
  onCancel: () => void;
  t: TFunction;
};

const expiredMinutes = 10;

export default function TFAuthModal({ email, isOpen, onCancel, t }: Props) {
  const [otp, setOtp] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [deadLine, setDeadLine] = useState<number>(0);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setDeadLine(Date.now() + expiredMinutes * 60 * 1000);
  }, []);

  const onSubmit = async (auth_password: string) => {
    try {
      setIsLoading(true);
      const browserData = await getBrowserClientData();
      cancelTokenRef.current = axios.CancelToken.source();
      const response = await auth.verifyAuth({ auth_password, email }, cancelTokenRef.current.token);

      setCookie('athena-token', response.data.access_token, 1);
      setCookie('refresh_token', response.data.refresh_token, 1);
      const form_data = new FormData();
      form_data.append('browser_data', JSON.stringify(browserData));
      message.destroy();
      navigate('/');

      await api.sendBrowserFingerprint(form_data);
      onCancel();
    } catch (err: any) {
      if (err?.message === 'Aborted' || err?.name === 'CanceledError') return;
      message.error('Wrong code please try again');
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (value: string) => {
    cancelTokenRef.current?.cancel();
    if (value.length === 6) {
      onSubmit(value);
    }
    setOtp(value);
  };

  return (
    <Modal
      onCancel={onCancel}
      open={isOpen}
      title={t('login.modals.verify.title')}
      okText={t('main.confirmButton')}
      cancelText={t('main.cancelButton')}
      classNames={{
        body: classes.body,
      }}
      okButtonProps={{
        disabled: otp.length !== 6,
        loading: isLoading,
      }}
    >
      <Form>
        <Statistic.Countdown
          className={classes.countDown}
          onFinish={onCancel}
          title={t('login.modals.verify.expired')}
          value={deadLine}
          format='mm:ss'
        />
        <Form.Item>
          <Input.OTP
            style={{
              justifyContent: 'space-between',
              width: '80%',
              margin: '20px auto 0',
              display: 'flex',
            }}
            value={otp}
            onChange={onChange}
            autoFocus
          />
        </Form.Item>
        {/* <Typography.Paragraph>
          Если верификационный код не пришел, то для повторной отправки нажмите <Typography.Link>здесь</Typography.Link>
        </Typography.Paragraph> */}
      </Form>
    </Modal>
  );
}
