import { Button, message, Table } from 'antd';
import organizations from 'api/organizations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import { billingColumn } from './columns';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import TableLoader from 'components/Sceletons/TableLoader';
import classes from '../../Billing.module.scss';

const BillingTable = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const { userData } = useAppSelector((store) => store.userData);
  const { i18n, t } = useTranslation();

  const statusDict: any = {
      'awaiting_payment': t('Billing.columns.awaiting_payment'),
      'paid': t('Billing.columns.paid')
  }

  React.useEffect(() => {
      getBills();
  }, []);

  const getBills = async () => {
      setIsLoading(true);
      try {
          const response = await organizations.getUserBills();
          setDataTable(response.data.map((request: any) => ({ ...request, loading: false, key: request.id })));
      } catch (e: any) {
          message.error(t('Billing.error.failedGet'));
      } finally {
          setIsLoading(false);
      }
  };

  const customColumns = React.useMemo(
      () =>
          billingColumn.map((col) => ({
              ...col,
              render: (value: any, info: any) => renderCell({ value, info, col }),
              title: t(`Billing.columns.${col.key}`),
          })),
      [i18n.language, billingColumn]
  );

  const sortedData = React.useMemo(
      () => dataTable.sort((first, second) => (first.status === 'awaiting_payment' ? -1 : first.status - second.status)),
      [dataTable]
  );

  const renderCell = ({ value, info, col }: any) => {
      if (col.key === 'name') {
          return value.name
      }
      if (col.key === 'cost') {
          return `${value.cost}$`
      }
      if (col.key === 'user_link') {
          return (
              <div>
                  <Button disabled={info.status === 'paid'} type='primary' target='_blank' href={value}>
                      {t('main.payButton')}
                  </Button>
              </div>
          );
      }
      if (col.key === 'created_at') {
          return value && getDateWithTimeZone(value, userData?.date_format! || 'international');
      }
      if (col.key === 'status') {
          return <div style={{ color: value === 'paid' ? '#52cc74' : '#e14d4d' }}>{statusDict[value]}</div>
      }
  
      return value;
  };

  if (isLoading) {
    return (
      <div className={classes.scheduleWrap}>
        <TableLoader height={'calc(100vh - 165px)'} padding={15} />
      </div>
    );
  }

  return <Table
    dataSource={sortedData}
    columns={customColumns}
    pagination={false}
    size='middle'
    className={classes.scheduleTable}
    scroll={{
      y: 'calc(100vh - 220px)',
    }}
  />
}

export default BillingTable;
