import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { deleteCookie } from 'helpers/cookies';
import { IUserData } from 'interfaces';

export const prepareRooms = (response: IUserData) => {
  return response.available_rooms.map((room) => ({
    ...room,
    created_at: Date.parse(String(room.created_at)),
    last_action_timestamp: Date.parse(String(room.last_action_timestamp)),
  }
)).sort((a,b) => b.last_action_timestamp - a.last_action_timestamp);
}

export const fetchUserData = createAsyncThunk(
  'dataUser/fetchAll',
  async (props: {}, thunkAPI) => {
    try {
      const userInfo = await api.getUserInfo();
      return {
        ...userInfo.data,
        available_rooms: prepareRooms(userInfo.data),
      };
    } catch (e) {
      deleteCookie('athena-token');
      deleteCookie('refresh_token');
      window.location.href = `/login`;
      return thunkAPI.rejectWithValue('Error while retrieving user data');
    }
  }
);

export const fetchUserPermissions = createAsyncThunk(
  'permissionsUser/fetchAll',
  async (props: {idRoom: string}, thunkAPI) => {
    try {
      const userPermissions = await api.getUserPermissions(props.idRoom);      
      return userPermissions.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);
