import { DeleteOutlined, ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Popover } from 'antd';
import classNames from 'classnames';
import Spinner from 'components/Spinner/Spinner';
import { IChatMessage, messageStatusType } from 'interfaces';
import { FC } from 'react';
import classes from '../Messanger.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setAttemptCount, setChatMessage, setChatStatus, setCheckingMessages } from 'store/slices/chatsSlice';
import fetchChatData from 'store/reducers/chatCreator';

type statusesType = {
  popupContent?: JSX.Element;
};

const statusCases = {
  sent: () => (
    <div className={classes.messanger_checked}>
      <div className={classes.messanger_check}></div>
      <div className={classes.messanger_check}></div>
    </div>
  ),
  pending: () => <Spinner size={12} color='#fff' width='fit-content' />,
  viewed: () => (
    <div className={classNames(classes.messanger_checked, classes.messanger_viewed)}>
      <div className={classes.messanger_check}></div>
      <div className={classes.messanger_check}></div>
    </div>
  ),
  rejected: ({ popupContent }: statusesType) => (
    <Popover content={popupContent} placement='bottomRight' trigger='click'>
      <ExclamationCircleOutlined className={classes.messanger_rejected} size={12} width='fit-content' />
    </Popover>
  ),
};

type Props = {
  isFirst: boolean;
  data: IChatMessage;
  date: string;
  chatMessage: IChatMessage;
  contextMenu: (message: IChatMessage) => MenuProps['items'];
  replyOn: (chatMessage: IChatMessage, isRecieve: boolean) => JSX.Element;
};

const SendMessage: FC<Props> = ({ isFirst, date, data, contextMenu, replyOn, chatMessage }) => {
  const { checkingMessages, attemptCount } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();

  const onResend = () => {
    dispatch(setChatMessage({ message: data.message, id: data.id!, status: 'pending' }));
    dispatch(setAttemptCount(attemptCount + 1));
    dispatch(
      fetchChatData({
        data: {
          ...data,
          status: 'pending',
        },
        type: 'replace',
        status: 'pending',
      })
    );
    dispatch(setChatStatus('send'));
    dispatch(
      setCheckingMessages(
        checkingMessages.map((checkMessages) =>
          checkMessages.id === data.id ? { ...checkMessages, status: 'pending' } : checkMessages
        )
      )
    );
  };

  const onCancel = () => {
    dispatch(fetchChatData({ data, type: 'cancel' }));
    dispatch(setCheckingMessages(checkingMessages.filter((checkMessages) => checkMessages.id !== data.id)));
  };

  return (
    <Dropdown
      overlayClassName={classes.dropdown}
      menu={{ items: chatMessage.status === 'pending' ? [] : contextMenu(chatMessage) }}
      trigger={['contextMenu']}
    >
      <div
        id={chatMessage.id}
        className={classNames(
          classes.messanger_send,
          isFirst && classes.messanger_first,
          chatMessage.status === 'pending' && classes.messanger_loading
        )}
      >
        {chatMessage.replied_to && replyOn(chatMessage, false)}

        <p className={classes.messanger_messageText}>{data.message}</p>
        <div className={classes.messanger_messageTime}>
          <span>{date}</span>
          {statusCases[data.status]({
            popupContent: (
              <div>
                <Button className={classes.reject_buttons} onClick={onResend} icon={<ReloadOutlined />}>
                  Отправить повторно
                </Button>
                <br />
                <Button className={classes.reject_buttons} onClick={onCancel} icon={<DeleteOutlined />}>
                  Отменить отправку
                </Button>
              </div>
            ),
          })}
        </div>
      </div>
    </Dropdown>
  );
};

export default SendMessage;
