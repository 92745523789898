import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next';

const NDASectionLoader = (props: any) => {

  const { t } = useTranslation();

  return <ContentLoader 
    speed={2}
    width={'100%'}
    height={490}
    title={t('Documents.table.loader')}
    viewBox='0 0 800 490'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='0' rx='4' ry='4' width='800' height='400' /> 
    <rect x='0' y='430' rx='4' ry='4' width='800' height='50' /> 
  </ContentLoader>
}

export default NDASectionLoader;
