

const systemPages = ['rooms', 'report', 'profile', 'organization', 'billing', 'global-chats', '', 'undefined', 'tariffs'];
export const technicalPages = ['endedTariff', 'awaitingPayment']

export const getKeyFromLocation = (location: string, roomFromParams?: string) => {
  if (location.includes('/mobile')) return 'mobile'
  const previousPage = roomFromParams && location.replace(`/room/${roomFromParams}/`,'').split('/')[0]
  const isChatPrevious = previousPage === 'chat' ? location.replace(`/room/${roomFromParams}/`,'').split('/')[1] : null;
  const currentKey = isChatPrevious ? `${previousPage}/${isChatPrevious}` : String(previousPage);
  return currentKey
};

export default systemPages;
