import React from 'react';
import { Table, message } from 'antd';
import TableLoader from 'components/Sceletons/TableLoader';
import { getVisitorsColumn } from './columns/visitorsColumns';
import organizations from 'api/organizations';

import classes from './OrganizationSchedule.module.scss';
import { useTranslation } from 'react-i18next';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { useAppSelector } from 'store/hook';

const Visitors = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const columns = React.useMemo(() => getVisitorsColumn(t), [i18n.language]);  
  const { userData } = useAppSelector((state) => state.userData);

  React.useEffect(() => {
    getVisitors();
  }, []);

  const getVisitors = async () => {
    setIsLoading(true);
    try {
      const response = await organizations.getVisitors();
      console.log(response)
      setDataTable(
        response.data.map((sub: any) => ({
          ...sub,
          created_at: getDateWithTimeZone(sub.created_at, userData?.date_format!),
        }))
      );
    } catch (e: any) {
      console.log(e);
      message.error(t("Organizations.error.failedGetSubs"));
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={classes.loaderWrap}>
        <TableLoader 
          height={'calc(100vh - 280px)'}
        />
      </div>
    );
  }

  return (
    <div className={classes.scheduleWrap}>
      <Table
        dataSource={dataTable}
        columns={columns}
        pagination={false}
        size='middle'
        className={classes.scheduleTable}
        scroll={{
          y: 'calc(100vh - 355px)',
        }}
      />
    </div>
  );
};

export default Visitors;
