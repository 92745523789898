import { BellOutlined, LoadingOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Empty, Tooltip, Typography } from 'antd';
import api from 'api';
import classNames from 'classnames';
import RowLoader from 'components/Sceletons/RowLoader';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { TFunction } from 'i18next';
import { dateFormatType, INotification } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchRoom, fetchRoomNotifications } from 'store/reducers/roomsCreator';
import { setNotifications } from 'store/slices/dataRoomSlice';
import classes from './HeaderComponent.module.scss';
import { useNavigate } from 'react-router-dom';
import { setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { fetchUserPermissions } from 'store/reducers/userDataCreator';
import { setCurrentFolder } from 'store/slices/dataDocumentsSlice';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { setUserPermissions } from 'store/slices/dataPermissionsSlice';

type NotificationRowProps = {
  items: INotification[];
  index: number;
  t: TFunction;
  style: any;
  onItemClick: () => void;
  dateFormat: dateFormatType;
  arrUpdatingIds?: string[];
};

function NotificationRow({ items, index, t, style, onItemClick, dateFormat, arrUpdatingIds }: NotificationRowProps) {
  const date = getDateWithTimeZone(items[index].created_at, dateFormat);
  const isHoverableItem = items[index].link || items[index].status !== 1;
  
  return (
    <div style={style} className={classNames(classes.list_item, isHoverableItem && classes.hoverable)} onClick={onItemClick}>
      <Tooltip title={t(`Header.Notification.${items[index].type }`)}>
        <Typography.Title level={5} className={classes.list_item_title}>
          <span className={classes.list_item_inner_title}>
            {arrUpdatingIds!.includes(items[index].id) 
              ? <LoadingOutlined style={{color: 'lightblue'}}/>
              : (!items[index].status && <FontAwesomeIcon className={classes.list_item_dot} icon={faCircle} />)
            }{' '}
            {t(`Header.Notification.${items[index].type}`)}
          </span>
          <span className={classes.list_item_timestamp}>{date}</span>
        </Typography.Title>
      </Tooltip>
      <div className={classes.list_item_description}>
        {items[index].message}
      </div>
    </div>
  );
}

type Props = {
  dateFormat: dateFormatType;
};

const Notifications: React.FC<Props> = ({ dateFormat }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = React.useState(false);
  const [isLoadingNotifications, setIsLoadingNotifications] = React.useState(false);
  const [arrUpdatingIds, setArrUpdatingIds] = React.useState<string[]>([]);

  const { notifications, notificationStatus, dataRoom } = useAppSelector(state => state.dataRoom);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();
  const locale = i18n.language || 'en';

  const notificationRef = React.useRef<HTMLDivElement>(null);
  const notificationSignalRef = React.useRef<() => void>();

  const fetchNotifications = async (locale: string) => {
    setIsLoadingNotifications(true);
    const dispatchPromise = dispatch(fetchRoomNotifications({ locale }));
    notificationSignalRef.current = dispatchPromise.abort;
    await dispatchPromise;
    setIsLoadingNotifications(false);
  };

  React.useEffect(() => {
    const onOverLayoutClick = (event: globalThis.MouseEvent) => {
      const withinBoundaries = event.composedPath().includes(notificationRef.current!);

      if (!withinBoundaries) {
        setIsNotificationsOpen(false);
        // notificationSignalRef.current!();
      }
    };
    if (notificationRef.current) {
      document.addEventListener('click', onOverLayoutClick);
    }

    return () => {
      notificationSignalRef.current && notificationSignalRef.current();
      document.removeEventListener('click', onOverLayoutClick);
    };
  }, [notificationRef]);

  React.useEffect(() => {
    fetchNotifications(locale);
  }, [locale]);

  const onNotificationOpen = () => {
    // notificationSignalRef.current && notificationSignalRef.current();
    setIsNotificationsOpen((prev) => !prev);
  };

  const onViewBtnClick = () => {
    setIsLoadingNotifications(true);
    const newNotificationsIdxs: number[] = [];
    const newNotifications = notifications.filter((not, idx) => {
      if (not.status === 0) {
        newNotificationsIdxs.push(idx);
        return true;
      }

      return false;
    });

    Promise.all(
      newNotifications.map(async (not) => {
        return (await api.updateNotification({ notification_id: not.id, status: 1 })).data;
      })
    )
      .then((datas) => {
        let dataIdx = -1;
        const changedNotifications = notifications.map((not, idx) => {
          if (newNotificationsIdxs.includes(idx)) {
            dataIdx += 1;
            return datas[dataIdx];
          }

          return not;
        });

        dispatch(setNotifications(changedNotifications));
      })
      .finally(() => setIsLoadingNotifications(false));
  };

  const newNotificationsCount = React.useMemo(() => notifications.filter((not) => not.status === 0).length, [notifications]);
  const showNotificationsCount = newNotificationsCount === 0 || isLoadingNotifications || isNotificationsOpen;

  const notificationsCases = {
    pending: [1, 2, 3, 4].map((_, idx) => <RowLoader padding={'0 0 0 0'} width={350} height={90} key={`load-${idx}`} />),
    fulfilled: (
      <>
        <FixedSizeList
          itemCount={notifications.length}
          height={notifications.length > 3 ? 400 : notifications.length * 102}
          itemSize={102}
          itemData={notifications}
          width={'100%'}
        >
          {({ index, style }) => {
            const onItemClick = async () => {
              if (notifications[index].link) {
                navigate(String(notifications[index].link));
                const selectRoomTab = String(notifications[index].link).includes('prevTab=rooms') || String(notifications[index].link).includes('/documents/');
                if (selectRoomTab) dispatch(setSelectedKeys(['rooms']));

                setIsNotificationsOpen(false);
                const regFolderId = notifications[index]?.link?.match('/documents/(.*?)/');
                const folderId = regFolderId && regFolderId[1];
                const regRoomId = notifications[index]?.link?.match('/room/(.*?)/');
                const roomId = regRoomId ? regRoomId[1] : notifications[index].details.room_id; 

                if (!dataRoom && roomId) {                  
                  await dispatch(fetchUserPermissions({ idRoom: roomId }));
                  await dispatch(fetchRoom({ id: roomId }));
                  dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
                } else if (dataRoom?.id !== roomId) {
                  dispatch(setUserPermissions(null));
                  dispatch(setUserAndRoomStatus('fulfilledUserData'));
                } else if (folderId) {
                  const folder = await api.getFolderById(folderId, roomId);
                  const folderPath = folder.data.folder_path ? folder.data.folder_path?.reverse() : [];
                  localStorage.setItem('previousFolderPath', JSON.stringify(folderPath));
                  dispatch(setCurrentFolder(folderPath));
                } else {
                  dispatch(setCurrentFolder([]));
                }
              };

              if (notifications[index].status !== 1) {
                notifications[index].status !== 1 && setArrUpdatingIds(prev => [...prev, notifications[index].id]);
                const { data } = await api.updateNotification({ notification_id: notifications[index].id, status: 1 });
                setArrUpdatingIds(arrUpdatingIds.filter(id => id === notifications[index].id));
                dispatch(
                  setNotifications(notifications.map((notification) => (notification.id === data.id ? data : notification)))
                );
              } 
            };

            return (
              <NotificationRow
                dateFormat={dateFormat}
                onItemClick={onItemClick}
                t={t}
                style={style}
                items={notifications}
                index={index}
                arrUpdatingIds={arrUpdatingIds}
              />
            );
          }}
        </FixedSizeList>

        <Button
          loading={isLoadingNotifications}
          type='default'
          onClick={onViewBtnClick}
          className={classes.list_button}
        >
          {t('Header.Notification.btn')}
        </Button>
      </>
    ),
    empty: <Empty description={t('Header.Notification.empty')} />,
  };

  return (
    <>
      <div className={classes.notification} ref={notificationRef}>
        <div className={classes.notificationWrap} onClick={onNotificationOpen}>
          <Badge
            dot={Boolean(newNotificationsCount)}
            count={!showNotificationsCount ? newNotificationsCount : undefined}
            overflowCount={99}
          >
            <BellOutlined className={classes.notificationBell} />
          </Badge>
        </div>

        <div className={classNames(classes.notification_container, isNotificationsOpen && classes.notification_open)}>
          {notificationsCases[notificationStatus]}
        </div>
      </div>
    </>
  );
};

export default Notifications;
