import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/hook';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { EllipsisOutlined, FileExcelOutlined, FilePdfOutlined, PrinterOutlined } from '@ant-design/icons';
import { fetchConfidentLogs } from 'store/reducers/logsCreator';
import { setConfidentLogs } from 'store/slices/dataLogsSlice';
import { useTranslation } from 'react-i18next';
import LogsTable from 'components/LogsTable/LogsTable';
import classes from './NDAElements.module.scss';
import convert from 'api/convert';
import { getFile } from 'helpers/downloadSomeFile';
import dayjs from 'dayjs'
import api from 'api';
import { ILogs } from 'interfaces';
import { getlogsColumnsForPrint } from 'components/LogsTable/columns/logsColumns';
import { pdfHelper } from 'helpers/pdfHelper';

export default function NDAReport() {
  const [messageApi, contextHolder] = message.useMessage();
  const [downloadProcess, setDownloadProcess] = React.useState<string[]>([]);
  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);

  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { userData } = useAppSelector((store) => store.userData);
  const { confidentLogs, isLoadingConfidentLogs, errorConfident, logsForPrint } = useAppSelector((store) => store.logs);

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const locale = i18n.language || 'en'

  React.useEffect(() => {
    dataRoom && dispatch(fetchConfidentLogs({roomId: dataRoom?.id, locale}));
    return () => {
      dispatch(setConfidentLogs([]));
    }
  }, [dataRoom, locale]);

  React.useEffect(() => {
    errorConfident && showError(t('Confirm.errorConfidentLogs'));
  }, [errorConfident]);

  const showError = (error: string) => messageApi.error(error);

  const getNameFile = (format: string) => {
    const time = dayjs().format('HH.mm/DD.MM.YYYY');
    const dateTime = `${t('Confirm.reportNDA')}_${time}`;
    const roomFor = `${t('Confirm.roomFor')}_${dataRoom?.name}`;
    const userFor = `${t('Confirm.userFor')}_${userData?.first_name + ' ' + userData?.last_name}`
    return `${dateTime}.${roomFor}.${userFor}.${format}`
  }

  const exportPdfReport = async () => {
    setDownloadProcess(prev => [...prev, 'exportPdf']);
    try {
      if (dataRoom) {
        const res = await convert.getConfidentReportPDF(dataRoom?.id, locale);
        getFile(
          res.data as Blob,
          getNameFile('pdf')
        );
      }
    } catch (e) {
      message.error(t('Confirm.reportNDAErrorPDF'));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === 'exportPdf'));
    }
  };

  const exportXlsxReport = async () => {
    setDownloadProcess(prev => [...prev, 'exportXlsx']);
    try {
      if (dataRoom) {
        const res = await api.getConfidentLogsXLSX(dataRoom?.id, locale);
        getFile(
          res.data as Blob,
          getNameFile('xlsx')
        );
      }
    } catch (e) {
      message.error(t('Confirm.reportNDAErrorXLSX'));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === 'exportXlsx'));
    }
  };


  const printReport = async () => {
    setDownloadProcess(prev => [...prev, 'print']);
    api.logPrint(dataRoom?.id!, true);
    try {
      const logsColumns = getlogsColumnsForPrint(t);
      const arrOfLogs = logsForPrint && logsForPrint[0] ? logsForPrint : confidentLogs;
      pdfHelper<ILogs>(arrOfLogs, logsColumns, getNameFile(''));
    } catch (e) {
      message.error(t('Confirm.reportNDAErrorPrint'));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === 'print'));
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'exportPdf',
      className: classes.dropdownItem,
      label: (
        <Button
          loading={downloadProcess.includes('exportPdf')}
          onClick={exportPdfReport}
          icon={<FilePdfOutlined />}
          className={classes.dropdownButton}
          disabled={Boolean(downloadProcess[0]) || isLoadingConfidentLogs}
        >
          {t('Confirm.download')} PDF
        </Button>
      ),
    },
    {
      key: 'exportXlsx',
      className: classes.dropdownItem,
      label: (
        <Button
          loading={downloadProcess.includes('exportXlsx')}
          onClick={exportXlsxReport}
          icon={<FileExcelOutlined />}
          className={classes.dropdownButton}
          disabled={Boolean(downloadProcess[0]) || isLoadingConfidentLogs}
        >
          {t('Confirm.download')} XLSX
        </Button>
      ),
    },
    {
      key: 'print',
      className: classes.dropdownItem,
      label: (
        <Button
          loading={downloadProcess.includes('print')}
          onClick={printReport}
          icon={<PrinterOutlined />}
          className={classes.dropdownButton}
          disabled={Boolean(downloadProcess[0]) || isLoadingConfidentLogs}
        >
          {t('Confirm.print')}
        </Button>
      ),
    },
  ];

  return (
    <div
      className={classes.tabWrap}
      style={{ height: 'calc(100vh - 256px)', width: '100%', overflow: 'hidden' }}
    >
      {contextHolder}
      <Dropdown
        open={isOpenDropdown || Boolean(downloadProcess[0])}
        onOpenChange={(open) => setIsOpenDropdown(open)}
        menu={{ items }}
        placement='bottomLeft'
      >
        <Button icon={<EllipsisOutlined />} className={classes.actionsButton} />
      </Dropdown>
    
      <LogsTable 
        logs={confidentLogs}
        isLoading={isLoadingConfidentLogs}
        scroll={{ y: 'calc(100vh - 327px)' }}
        emptyHeight={'calc(100vh - 292px)'}
      />
    </div>
  )
}
