import { TFunction } from "i18next";
import classes from '../DocumentTable.module.scss';
import { IDocument } from "interfaces";

export const getDocumentMobileColumns = (t: TFunction) => [
  {
    title: t('Documents.table.columns.name'),
    dataIndex: 'name',
    key: 'name',
    // sorter: (a: IDocument, b: IDocument) => a.name.localeCompare(b.name),
    // ellipsis: true,
    width: '100%',
  }
];
