import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DataState {
  selectedKeys: string[];
  isMoreInfoHide: boolean;

  isLoadingProcess: boolean;
  isDeletingDocuments: boolean;
  isUploadingDocuments: boolean;
  isDownloadingDocuments: boolean;

  isMovingDocument: boolean;
  displayedDragger: boolean;

  isOpenDeleteEntityModal: boolean;
  isOpenMoveEntityModal: boolean;
  isOpenRenameEntityModal: boolean;
  isActiveContextMenu: boolean;
  isOpenShareEntityModal: boolean;
  isOpenFileDetailsModal: boolean;

  sideBarWidth: number;
  isResizing: boolean;
  isOpenSuccessfulPayment: boolean;
};

const initialState: DataState = {
  selectedKeys: ['/documents'],
  isMoreInfoHide: true,

  isLoadingProcess: false,
  isDeletingDocuments: false,
  isUploadingDocuments: false,
  isDownloadingDocuments: false,

  isMovingDocument: false,
  displayedDragger: false,

  isOpenDeleteEntityModal: false,
  isOpenMoveEntityModal: false,
  isActiveContextMenu: false,
  isOpenRenameEntityModal: false,
  isOpenShareEntityModal: false,
  isOpenFileDetailsModal: false,

  sideBarWidth: Number(localStorage.getItem('sideBarWidth')) || 400,
  isResizing: false,
  isOpenSuccessfulPayment: false,
};

const windowStateSlice = createSlice({
  name: 'windowState',
  initialState,
  reducers: {
    setSelectedKeys(state, action: PayloadAction<string[]>) {
      state.selectedKeys = action.payload;
    },
    setMoreInfoHidden(state, action: PayloadAction<boolean>) {
      state.isMoreInfoHide = action.payload;
    },
    setIsLoadingProcess(state, action: PayloadAction<boolean>) {
      state.isLoadingProcess = action.payload;
    },
    setIsDeletingDocuments(state, action: PayloadAction<boolean>) {
      state.isDeletingDocuments = action.payload;
    },
    setIsUploadingDocuments(state, action: PayloadAction<boolean>) {
      state.isUploadingDocuments = action.payload;
    },
    setIsDownloadingDocuments(state, action: PayloadAction<boolean>) {
      state.isDownloadingDocuments = action.payload;
    },
    setDisplayedDragger(state, action: PayloadAction<boolean>) {
      state.displayedDragger = action.payload;
    },
    setMovingDocument(state, action: PayloadAction<boolean>) {
      state.isMovingDocument = action.payload;
    },
    setIsOpenDeleteEntityModal(state, action: PayloadAction<boolean>) {
      state.isOpenDeleteEntityModal = action.payload;
    },
    setIsOpenMoveEntityModal(state, action: PayloadAction<boolean>) {
      state.isOpenMoveEntityModal = action.payload;
    },
    setIsActiveContextMenu(state, action: PayloadAction<boolean>) {
      state.isActiveContextMenu = action.payload;
    },
    setIsOpenRenameEntityModal(state, action: PayloadAction<boolean>) {
      state.isOpenRenameEntityModal = action.payload;
    },
    setIsOpenShareEntityModal(state, action: PayloadAction<boolean>) {
      state.isOpenShareEntityModal = action.payload;
    },
    setIsOpenFileDetailsModal(state, action: PayloadAction<boolean>) {
      state.isOpenFileDetailsModal = action.payload;
    },
    setSideBarWidth(state, action: PayloadAction<number>) {      
      state.sideBarWidth = action.payload;
    },
    setIsResizing(state, action: PayloadAction<boolean>) {      
      state.isResizing = action.payload;
    },
    setIsOpenSuccessfulPayment(state, action: PayloadAction<boolean>) {      
      state.isOpenSuccessfulPayment = action.payload;
    },
  },
});

const windowStateReducer = windowStateSlice.reducer;

export const {
  setSelectedKeys,
  setMoreInfoHidden,
  setIsLoadingProcess,
  setIsDeletingDocuments,
  setIsUploadingDocuments,
  setIsDownloadingDocuments,
  setDisplayedDragger,
  setMovingDocument,
  setIsOpenDeleteEntityModal,
  setIsOpenMoveEntityModal,
  setIsActiveContextMenu,
  setIsOpenRenameEntityModal,
  setIsOpenShareEntityModal,
  setIsOpenFileDetailsModal,
  setSideBarWidth,
  setIsResizing,
  setIsOpenSuccessfulPayment,
} = windowStateSlice.actions;
export default windowStateReducer;
