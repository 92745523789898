import { TFunction } from 'i18next';
import { ILogs, ISimpleUserForAprove } from 'interfaces';
import { availableKeysInDictionary, keysAvailableToTranslate } from './keysInDictionary';
import dayjs from 'dayjs';
import { defaultGroups } from 'store/reducers/usersGroupCreator';

export const prepareLogForDisplay = (choosenLog: ILogs, t: TFunction, ruActions: { ru: string[]; actual: string[] }) => {
  const logData = Object.entries(choosenLog);

  const extraInfo = choosenLog?.details ? Object.entries(choosenLog?.details) : [];
  const extraInfoWithoutEmail = extraInfo.filter(elem => elem[0] !== 'email')

  const preparededData = [...logData, ...extraInfoWithoutEmail].map((row) => {
    const nameOfRow = row[0];
    const valueOfRow = row[1];

    const rowName = availableKeysInDictionary.includes(nameOfRow) ? t(`Logs.logsModal.${nameOfRow}`) : nameOfRow;

    if (['before', 'after'].includes(nameOfRow)) {
      // изменение пермишеннов папки
      let details: any;
      if (valueOfRow instanceof Array) {                      
        details = valueOfRow[0]
          ? valueOfRow?.map(permission => t(`Logs.logsModal.${permission}`)).join(', ')
          : t('Logs.logsModal.noPermissions');

        details = details[0].toUpperCase() + details.slice(1);
      } else {        
        // изменение настроек комнаты или изменение организации
        details = Object.entries(valueOfRow).map(elem => {
          let nameOfSettingField = keysAvailableToTranslate.includes(elem[0])
            ? t(`Logs.logsModal.${elem[0]}`)
            : elem[0];

          let valueOfSettingField;

          if (elem[0] === 'agreement_enable'){
            valueOfSettingField = t(`Logs.logsModal.${String(elem[1])}`)
          } else if (elem[0] === 'agreement_file') {
            valueOfSettingField = elem[1] === null ? '-' : `"${elem[1]}"`;
          }

          // изменение изменение подробных данных об организации
          if (elem[0].includes('__')){
            const arrNamesOrg = elem[0].split('__')
            nameOfSettingField = t(`Organizations.fields.${arrNamesOrg[1]}`);
          }

          nameOfSettingField = nameOfSettingField[0].toUpperCase() + nameOfSettingField.slice(1);

          return  [nameOfSettingField, valueOfSettingField || elem[1] || t(`Organizations.fields.empty`)];
        });
      };

      return [rowName, details];
    };

    if (nameOfRow === 'agreement_data' || nameOfRow === 'signing_data') {
      // запрос согласования документа или на подписание документа
      let usersForSigning: any[] = [];

      Object.entries(valueOfRow).forEach((elem: [string, any])=> {
        let nameOfSettingField = t(`Logs.logsModal.${nameOfRow}_request`);
        nameOfSettingField = nameOfSettingField[0].toUpperCase() + nameOfSettingField.slice(1);

        elem[1]?.users?.forEach((user: ISimpleUserForAprove, i: number) => {
          const correctGroupName = defaultGroups.includes(elem[1]?.group_name) ? t(`Users.table.${elem[1]?.group_name}`) : elem[1]?.group_name;

          usersForSigning.push([ nameOfSettingField, user.user_name ]);
          usersForSigning.push([ t(`Logs.logsModal.user's_email`), user.user_email ]);
          usersForSigning.push([ t(`Logs.logsModal.user's_group`), correctGroupName ]);
          (i < elem[1]?.users.length - 1) && usersForSigning.push([ '', '' ])
        });
      });

      return [rowName, usersForSigning];
    }
  
    // исключения которые необходимо переводить в соотсетствии с выбранным языком
    if(nameOfRow === 'entityname' && valueOfRow === 'отсутствует'){
      const actionIdx = ruActions.ru.findIndex((action) => valueOfRow === action);   
      return [rowName, ruActions.actual[actionIdx]];
    }
    if((nameOfRow === 'folder_name' && choosenLog?.details?.folder_id === null)){
      return [rowName, t('Documents.modals.permissions.rootFiles')];
    }
    // исключения которые необходимо привести в формат строки
    if(nameOfRow === 'emails') {
      return [rowName, valueOfRow.join('; ')];
    }

    if(nameOfRow === 'confidential_date') {
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      const inSeconds = dayjs(valueOfRow).valueOf();
      const rightTime = dayjs(inSeconds - offset);
      return [rowName, rightTime.format('DD.MM.YYYY')];
    }

    // дефолтный возврат
    return [rowName, valueOfRow || '-'];
  });

  return preparededData;
};
