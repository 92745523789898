import { Collapse, CollapseProps, Typography } from 'antd';
import { TFunction } from 'i18next';
import classes from '../FAQ.module.scss';

const { Title, Paragraph } = Typography;

const initialItems = [
  [1, 2, 3, 4],
  [1, 2, 3, 4, 5, 6],
  [1, 2, 3, 4, 5, 6, 7],
  [1, 2, 3, 4, 5, 6, 7],
  [1, 2, 3, 4, 5, 6],

  [1, 2, 3, 4, 5],
  [1],
  [1, 2, 3],
  [],
  [1, 2, 3, 4],
];

export default function FAQTab({ t }: { t: TFunction }) {
  const modifiedItems: CollapseProps['items'][] = initialItems.map((el, idx) =>
    el.map((el) => ({
      key: `${idx + 1}.${el}`,
      label: <Title level={5}>{t(`FAQ.${idx + 1}_${el}.question`)}</Title>,
      children: (
        <Paragraph>
          <span
            dangerouslySetInnerHTML={{
              __html: t(`FAQ.${idx + 1}_${el}.answer`),
            }}
          />
        </Paragraph>
      ),
    }))
  );

  return (
    <div>
      {modifiedItems.map((items, idx) =>
        items?.length ? (
          <div key={`FAQ-${idx}`} className={classes.section}>
            <Title level={2}>{t(`FAQ.${idx + 1}`)}</Title>
            <Collapse items={items} defaultActiveKey={[`${idx}.1`]} />
          </div>
        ) : null
      )}
    </div>
  );
}
