import React from 'react'
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './Billing.module.scss';
import BillingTable from './tables/BillingTable/BillingTable';
import RoomsBillingTable from './tables/RoomsBillingTable/RoomsBillingTable';

const Billing = () => {
  const { t } = useTranslation();

    return (
      <div className={classes.scheduleWrap}>
        <Tabs
          defaultActiveKey='billing'
          type='card'
          items={[
            {
              label: t('Header.profile.billing'),
              key: 'billing',
              children: <BillingTable />,
            },
            {
              label: t('Billing.roomsBilling'),
              key: 'roomsBilling',
              children: <RoomsBillingTable />,
            },
        ]}
        />
      </div>
    )
}

export default Billing
