import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { CasesTab, FAQTab } from './Tabs';
import classes from './FAQ.module.scss';

export default function FAQ() {
  const { t } = useTranslation('translation', { keyPrefix: 'FAQ' });
  const items = [
    {
      key: 'FAQ',
      label: t('FAQ.title'),
      children: (
        <div className={classes.container}>
          <FAQTab t={t} />
        </div>
      ),
    },
    {
      key: 'Cases',
      label: t('Cases.title'),
      children: (
        <div className={classes.container}>
          <CasesTab t={t} />
        </div>
      ),
    },
  ];

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainSpace}>
        <Tabs items={items} className={classes.tabs} />
      </div>
    </div>
  );
}
