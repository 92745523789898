import { IDocument, IFolder } from 'interfaces';

const sortItems = (items: (IDocument | IFolder)[], referenceOrder: string[]) => {
    // Создаем копию массива перед сортировкой
    const itemsCopy = [...items]; // или можно использовать items.slice()
  
    return itemsCopy.sort((a, b) => {
      // 1. Если тип 'folder', сортируем их первыми
      if (a.type === 'folder' && b.type !== 'folder') {
        return -1;
      }
      if (a.type !== 'folder' && b.type === 'folder') {
        return 1;
      }
      
      // 2. Сортировка по referenceOrder для элементов с type != 'folder'
      if (a.type !== 'folder' && b.type !== 'folder') {
        const indexA = referenceOrder.indexOf(a.name);
        const indexB = referenceOrder.indexOf(b.name);
        
        // Если оба элемента есть в referenceOrder, сортируем по порядку
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }
        
        // Если только один элемент есть в referenceOrder, он идет первым
        if (indexA !== -1) {
          return -1;
        }
        if (indexB !== -1) {
          return 1;
        }
      }
      
      // 3. Если ничего из вышеуказанного, сортируем по алфавиту
      return a.name.localeCompare(b.name);
    });
  };
  
export { sortItems }