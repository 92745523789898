import { FC } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import classNames from 'classnames';
import classes from '../Messanger.module.scss';
import { IChatMessage } from 'interfaces';
import { MenuProps } from 'antd';

type Props = {
  date: string;
  isFirst: boolean;
  chatMessage: IChatMessage;
  contextMenu: (message: IChatMessage) => MenuProps['items'];
  replyOn: (chatMessage: IChatMessage, isRecieve: boolean) => JSX.Element;
};

const RecieveMessage: FC<Props> = ({ date, isFirst, chatMessage, contextMenu, replyOn }) => {
  return (
    <Dropdown overlayClassName={classes.dropdown} menu={{ items: contextMenu(chatMessage) }} trigger={['contextMenu']} >
      <div id={chatMessage.id} className={classNames(classes.messanger_recieve, isFirst && classes.messanger_first)}>
        {isFirst && <Avatar size={30} icon={<UserOutlined />} className={classes.messanger_recieveAvatar} />}
        <div className={classNames(classes.messanger_recieveMessage, isFirst && classes.messanger_first)}>
          <div className={classes.messanger_userData}>
            <span className={classes.messanger_userName}>{chatMessage.user_name}</span>
          </div>

          {chatMessage.replied_to && replyOn(chatMessage, true)}

          <p className={classes.messanger_messageText}>{chatMessage.message}</p>
          <p className={classes.messanger_messageTime}>
            <span>{date}</span>
          </p>
        </div>
      </div>
    </Dropdown>
  );
};

export default RecieveMessage;
