import { TFunction } from 'i18next';

export const getRoomsBillingColumns = (t: TFunction) => [
  {
      title: t('Billing.roomsColumns.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
  },
  {
      title: t('Billing.roomsColumns.paid_period'),
      dataIndex: 'paid_period',
      key: 'paid_period',
      width: '20%',
  },
  // {
  //     title: t('Billing.roomsColumns.next_payment'),
  //     dataIndex: 'next_payment',
  //     key: 'next_payment',
  //     width: '20%',
  // },
  {
      title: t('Billing.roomsColumns.cost'),
      dataIndex: 'cost',
      key: 'cost',
      width: '18%',
  },
  {
      title: t('Billing.roomsColumns.payment_status'),
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '18%',
  },
  {
      title: t('Billing.roomsColumns.tariff_name'),
      dataIndex: 'tariff_name',
      key: 'tariff_name',
      width: '24%',
  },
];
