import { TFunction } from "i18next";

export const getVisitorsColumn = (t: TFunction) => [
  {
    title: t("Organizations.columns.visitors.id"),
    dataIndex: 'ddg1',
    key: 'ddg1',
    width: '40%',
  },
  {
    title: t("Organizations.columns.visitors.last_visit"),
    dataIndex: 'created_at',
    key: 'created_at',
    width: '40%',
  },
  {
    title: t("Organizations.columns.visitors.visits_count"),
    dataIndex: 'visits',
    key: 'visits',
    width: '20%',
  },
];
  